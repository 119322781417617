import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";

function Items({ currentItems }) {
    return (
        <>
            {currentItems && currentItems.map((item) => (
                <div key={item._id} className="w-full">
                    <Product
                        _id={item._id}
                        img={item.img}
                        images={item.images}
                        productName={item.productName}
                        promo={item.promo}
                        price={item.price}
                        color={item.color}
                        badge={item.badge}
                        des={item.des}
                    />
                </div>
            ))}
        </>
    );
}

const Pagination = ({ itemsPerPage, category, filter }) => {
    const [items, setItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Page courante (1-based index)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Lecture du paramètre "page" dans l'URL, par défaut 1
        const queryParams = new URLSearchParams(location.search);
        const pageFromURL = parseInt(queryParams.get("page") || "1", 10);

        // Mettre à jour la page courante et le offset
        setCurrentPage(pageFromURL);

        // Calcul de l'offset : comme pageFromURL est 1-based, l'offset est (page - 1)
        const newOffset = (pageFromURL - 1) * itemsPerPage;
        setItemOffset(newOffset);
    }, [location.search, itemsPerPage]);

    useEffect(() => {
        let filteredItems =
            category !== "ALL"
                ? paginationItems.filter((item) => item.category === category)
                : paginationItems;

        if (filter === "PROMO") {
            filteredItems = filteredItems.filter((item) => item.promo);
        }

        setItems(filteredItems);
    }, [category, filter]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        // Comme event.selected commence à 0, on ajoute 1 pour avoir une pagination 1-based
        const selectedPage = event.selected + 1;
        navigate(`?page=${selectedPage}`);
    };

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
                <Items currentItems={currentItems} />
            </div>
            <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
                <ReactPaginate
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel=""
                    // Force la page active en fonction de currentPage (convertie en 0-based pour ReactPaginate)
                    forcePage={currentPage - 1}
                    pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
                    pageClassName="mr-6"
                    containerClassName="flex text-base font-semibold font-titleFont py-10"
                    activeClassName="bg-black text-white"
                />

                <p className="text-base font-normal text-lightText">
                    Products from {itemOffset + 1} to {Math.min(endOffset, items.length)} of {items.length}
                </p>
            </div>
        </div>
    );
};

export default Pagination;
