import React, { useState } from "react";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import {GoTriangleDown} from "react-icons/go";


const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [category, setCategory] = useState("ALL");
  const [filter, setFilter] = useState("ALL");
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  return (
    <div className="max-w-container mx-auto px-4">

      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 pt-5 gap-10 justify-center">

        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <Pagination itemsPerPage={itemsPerPage} category={"electronique"} filter={filter} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
