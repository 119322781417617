import {


    robot1,
    robot2,
    robot3,
    robot4,

    raclette1,
    raclette2,
    raclette3,
    raclette4,

    porteSac,
    porteSac2,
    porteSac3,
    porteSac4,

    etagiereLumineuse,
    etagiereLumineuse2,
    etagiereLumineuse3,
    etagiereLumineuse4,
    etagiereLumineuse5,

    purificateur,
    purificateur2,
    purificateur4,
    purificateur5,
    purificateur6,
    purificateur7,

    turboDouche,
    turboDouche2,
    turboDouche3,
    turboDouche4,
    turboDouche5,

    etagiereDouble,
    etagiereDouble2,
    etagiereDouble3,
    etagiereDouble4,
    etagiereDouble5,
    etagiereDouble6,

    manteau1,
    manteau2,
    manteau3,
    manteau4,
    manteau5,
    manteau6,
    manteau7,

    guirlandes,
    guirlandes2,
    guirlandes3,
    guirlandes4,
    guirlandes5,
    guirlandes6,
    guirlandes7,
    guirlandes8,
    guirlandes9,

    destributeurD1,
    destributeurD2,
    destributeurD3,
    destributeurD4,

    friteuse,
    friteuse2,
    friteuse3,
    friteuse4,
    friteuse5,
    friteuse6,
    friteuse7,
    friteuse8,

    humidificateur2,
    humidificateur,
    humidificateur3,
    humidificateur4,
    humidificateur5,
    humidificateur6,
    humidificateur7,
    brosseVitres,
    brosseVitres2,
    brosseVitres3,
    brosseVitres4,
    brosseVitres5,
    secheLinge,
    secheLinge2,
    secheLinge3,
    secheLinge4,
    secheLinge5,
    nebuliseur,
    nebuliseur2,
    nebuliseur3,
    nebuliseur4,
    nebuliseur5,
    canne,
    canne2,
    canne3,
    canne4,
    canne5,
    safetyBelt,
    safetyBelt2,
    safetyBelt3,
    safetyBelt4,
    safetyBelt5,
    safetyBelt6,
    robot24,
    robot21,
    robot22,
    robot23,
    ceinamein5,
    ceinamein,
    ceinamein2,
    ceinamein3,
    ceinamein4,
    Astronaut7,
    Astronaut,
    Astronaut2,
    Astronaut3,
    Astronaut4,
    Astronaut5,
    Astronaut6,
    sticker,
    sticker2,
    sticker3,
    sticker4,
    sticker5,
    sticker6,
    lampeMurale,
    lampeMurale2,
    lampeMurale3,
    lampeMurale4,
    lampeMurale5,
    lampeMurale6,
    support2,
    support3,
    boudinPorte5,
    boudinPorte,
    boudinPorte2,
    boudinPorte3,
    boudinPorte4,
    MopNettoyage,
    MopNettoyage5,
    MopNettoyage4,
    MopNettoyage3,
    MopNettoyage2,
    MassageTete4,
    MassageTete,
    MassageTete2,
    MassageTete3,
    salleDeBain,
    salleDeBain2,
    salleDeBain3,
    sandrier6,
    sandrier,
    sandrier2,
    sandrier3,
    sandrier4,
    sandrier5,
    sechLinge3,
    sechLinge,
    sechLinge2,
    lenovo4,
    lenovo,
    lenovo2,
    lenovo3,
    tendeuse,
    tendeuse2,
    tendeuse3,
    file4,
    file1,
    file2,
    file3,
    retro1,
    retro2,
    retro3,
    retro4,
    retro5,
    retro6,
    kit5,
    kit1,
    kit2,
    kit3,
    kit4,
    peluche6,
    peluche1,
    peluche2,
    peluche3,
    peluche4,
    peluche5,
    carBleutooth,
    carBleutooth2,
    carBleutooth3,
    carBleutooth4,
    LampeCrystal5,
    LampeCrystal,
    LampeCrystal2,
    LampeCrystal3,
    LampeCrystal4,
    LampeDiamant3,
    LampeDiamant,
    LampeDiamant2,
    LampeDiamant4,
    LampeDiamant5,
    Rouleau,
    Rouleau2,
    Rouleau3,
    Rouleau4,
    Rouleau5,
    Vantouse,
    Vantouse2,
    Vantouse3,
    Vantouse4,
    Vitre,
    Vitre2,
    Vitre3,
    Vitre4,
    miniShaver,
    miniShaver2,
    miniShaver3,
    miniShaver4,
    lenovob4,
    lenovob,
    lenovob1,
    lenovob2,
    lenovob3,
    mx5,
    mx1,
    mx2,
    mx3,
    mx4,
    A6,
    A1,
    A2,
    A3,
    A4,
    A5,
    inkax5,
    inkax1,
    inkax2,
    inkax3,
    inkax4,
    inkaxT031,
    inkaxT032,
    inkaxT033,
    inkaxT034,
    inkaxT035,
    bt1,
    bt2,
    bt3,
    bt4,
    bt5,
    I9S5,
    I9S1,
    I9S2,
    I9S3,
    I9S4, M101, M102, M103, M104, M105, M106, MI5, MI1, MI2, MI3, MI4, TWS5, TWS1, TWS2, TWS3, TWS4,


} from "../assets/images/index";


export const bonnesAffaires = [
    {_id: "1135",
        img: kit1,
        images: [kit3,kit4,kit5,kit1,kit2, ],
        promo: 50,
        productName: "Ecouteurs sans fil I12 TWS",
        price: "32.00",
        color: "blanc",
        badge: true,
        des: `Écouteurs Bluetooth V5.0 : Profitez d'une qualité sonore exceptionnelle et d'une expérience utilisateur fluide !<br /><br />

<b>Caractéristiques :</b><br />
- <b>Version Bluetooth :</b> Dual-mode Bluetooth V5.0 avec une ultra-basse consommation d'énergie.<br />
- <b>Protocoles supportés :</b> A2P1.3 / HFP1.6 / HSP1.2 / AVRCP1.6 / D11.3.<br />
- <b>Bande d'utilisation Bluetooth :</b> 2.4 GHz.<br />
- <b>Distance sans fil :</b> Jusqu'à 15 m.<br />
- <b>Poids :</b> Environ 4-5 g (par écouteur).<br />
- <b>Temps de charge :</b> 1 à 1,5 heure.<br />
- <b>Temps d'appel :</b> Environ 4 à 4,5 heures.<br />
- <b>Boîtier de charge :</b> Capacité de 300 mAh, mini et portable, permet plusieurs recharges des écouteurs.<br /><br />

<b>Points forts :</b><br />
- <b>Qualité audio exceptionnelle :</b> Bluetooth 5.0 offre une qualité sonore cristalline avec des aigus nets, même dans des environnements bruyants.<br />
- <b>Commandes vocales :</b> Affichage du niveau de batterie, commandes vocales et fonction Siri intégrée pour une expérience simplifiée.<br />
- <b>Paires automatiques :</b> Sortez les deux écouteurs du boîtier, ils s'allument et se connectent automatiquement entre eux, puis à votre appareil Bluetooth.<br />
- <b>Conception ergonomique :</b> Idéal pour le sport, la course, la randonnée, le vélo, le gymnase, etc., offrant une tenue stable et confortable.<br /><br />

<b>Pourquoi les choisir ?</b><br />
Ces écouteurs Bluetooth offrent une expérience pratique, stable et agréable pour toutes vos activités, avec une autonomie prolongée et une qualité sonore incomparable. Ne ratez pas l'opportunité de profiter d'un son haut de gamme où que vous soyez.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique",


    },
    {_id: "1",
        img: mx1,
        images: [mx3,mx5,mx4, mx2, mx1,],
        promo: 40,
        productName: "Ecouteur mx10 pro ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `Écouteurs sans fil : Une simplicité magique associée à une technologie avancée.<br /><br />

<b>Caractéristiques principales :</b><br />
- <b>Connexion instantanée :</b> Sortez-les du boîtier et ils se connectent immédiatement à vos appareils. Mettez-les dans vos oreilles, et ils sont prêts à l'emploi.<br />
- <b>Qualité d'appel exceptionnelle :</b> Les appels binauraux sont aussi clairs que des conversations en face à face, grâce à la réduction numérique du bruit.<br />
- <b>Effet sonore haute fidélité :</b> Conception dynamique de l'unité offrant une excellente analyse audio, sans distorsion, pour une expérience immersive inégalée.<br />
- <b>Assistant vocal Siri :</b> Appuyez longuement pendant 2 secondes pour activer Siri. Vérifiez la météo, demandez une navigation ou passez des appels avec facilité.<br />
- <b>Toucher 3D :</b> Écran tactile haute sensibilité intégré pour des commandes intuitives. Effectuez rapidement une variété d'opérations en touchant légèrement.<br />
- <b>Conception ergonomique :</b> Ajustement confortable pour un port prolongé sans gêne.<br /><br />

<b>Pourquoi les choisir ?</b><br />
Ces écouteurs allient simplicité et innovation. Avec leur connexion instantanée, leur excellente qualité sonore, et leur design ergonomique, ils vous accompagnent partout avec élégance et praticité.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },
    {_id: "9",
        img:MI1 ,
        images: [MI1,MI2,MI3,MI4,MI5,],
        promo: 20,
        productName: "Écouteurs  Bluetooth  Mi True ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques principales :</b><br /><br />
💡 <b>Légèreté et Confort :</b> Chaque écouteur ne pèse que 4,1 g, offrant un confort ultime même lors d'une utilisation prolongée.<br /><br />
🔋 <b>Autonomie Élevée :</b> Profitez d'environ 4 heures d'appels ou de musique avec une seule charge. Grâce à l'étui de charge de 300 mAh, bénéficiez d'une autonomie totale de 12 heures pour vos déplacements.<br /><br />
⚡ <b>Chargement Rapide :</b> Rechargez vos écouteurs en seulement 1,5 heure, et l'étui de charge en 2 heures, pour une utilisation continue tout au long de la journée.<br /><br />
📶 <b>Bluetooth 5.0 :</b> Profitez d'une connexion stable et rapide jusqu'à 10 mètres de distance, sans interruption, grâce à la technologie Bluetooth 5.0.<br /><br />
🎧 <b>Connexion Automatique :</b> Connectez vos écouteurs manuellement la première fois, puis laissez la magie opérer ! Les écouteurs se connectent automatiquement à votre appareil dès la sortie de l'étui.<br /><br />

<b>Spécifications Techniques :</b><br /><br />
- <b>Poids net d’un écouteur :</b> Environ 4,1 g<br />
- <b>Autonomie d’appel :</b> Environ 4 heures<br />
- <b>Capacité de la batterie des écouteurs :</b> 43 mAh chacun<br />
- <b>Capacité de la batterie de l’étui de charge :</b> 300 mAh<br />
- <b>Temps de charge des écouteurs :</b> Environ 1,5 heure<br />
- <b>Temps de charge de l’étui :</b> Environ 2 heures<br />
- <b>Connectivité sans fil :</b> Bluetooth 5.0<br />
- <b>Poids total de l’étui de charge :</b> Environ 35,4 g<br />
- <b>Portée de la communication :</b> 10 mètres (sans obstacle)<br />
- <b>Dimensions des écouteurs :</b> 26,65 × 16,4 × 21,6 mm<br />
- <b>Dimensions de l’étui de charge :</b> 62 x 40 x 27,2 mm<br />
- <b>Protocole Bluetooth :</b> AVRCP/HSP/HFP/A2DP<br /><br />

Avec les Mi True Wireless Earbuds, bénéficiez d'une liberté totale pour écouter votre musique, passer vos appels, et vous immerger dans une qualité sonore sans compromis. 🌟<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },


    {_id: "10",
        img:TWS1 ,
        images: [TWS2,TWS3,TWS4,TWS5,TWS1,],
        promo: 20,
        productName: "Écouteurs  Bluetooth TWS M88 ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques principales :</b><br /><br />
- 🌟 <b>Réduction Active du Bruit :</b> Bloquez les bruits indésirables et plongez dans votre musique grâce à la fonction de réduction active du bruit.<br />
- 📶 <b>Bluetooth 5.3 Sans Fil :</b> Profitez de connexions plus rapides et stables grâce à la dernière technologie Bluetooth 5.3.<br />
- 💡 <b>Affichage LED :</b> Suivez l'état de la batterie et de la charge grâce à l'affichage LED pratique sur l'étui de charge.<br />
- 💧 <b>Étanchéité :</b> Ne laissez pas la pluie ou la transpiration interrompre votre musique pendant vos entraînements ou activités en plein air.<br /><br />

<b>Description :</b><br />
M88 TWS, écouteurs sans fil Bluetooth avec boîtier de charge à LED, micro intégré et écouteurs résistants à l'eau. Idéal pour une expérience audio immersive et pratique.<br /><br />

<b>Contenu de l'emballage :</b><br />
- 1 paire d'écouteurs<br />
- 1 boîtier de charge<br />
- 1 câble<br />
- 1 manuel d'utilisation<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"
    },
    {_id: "8",
        img:M101 ,
        images: [ M101,M104,M105,M106,M102,M103,],
        promo: 50,
        productName: "Écouteurs  Bluetooth M10 pro",
        price: "38.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques Principales :</b><br /><br />
- 🖐️ <b>Contrôle Tactile Intuitif :</b> Abandonnez les boutons traditionnels. Un simple toucher permet de répondre/terminer/rejeter un appel, lire/mettre en pause votre musique, ajuster le volume ou activer l'assistant vocal.<br />
- 📶 <b>Bluetooth 5.3 :</b> Profitez d'une connectivité sans fil rapide et stable avec la dernière version Bluetooth 5.3.<br />
- 🔋 <b>Autonomie Optimale :</b> Batterie rechargeable intégrée offrant 80 minutes de temps de musique et jusqu'à 60 heures en veille.<br />
- 🎽 <b>Usage Polyvalent :</b> Idéal pour le sport, le travail ou vos activités quotidiennes, ces écouteurs s'adaptent à toutes vos situations.<br /><br />

<b>Spécifications Techniques :</b><br />
- Version Bluetooth : 5.3<br />
- Application : Sport, travail<br />
- Connectivité : Sans fil<br />
- Chipset : Airoha<br />
- Réponse en fréquence : 20-20000 Hz<br />
- Temps de musique : 80 minutes<br />
- Autonomie en veille : 60 heures<br /><br />

Avec ces écouteurs sans fil, profitez d'une expérience audio pratique et confortable, adaptée à tous vos besoins.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },
    {_id: "7",
        img:I9S1 ,
        images: [I9S3,I9S4,I9S1,I9S2,I9S5,],
        promo: 20,
        productName: "Écouteurs Bluetooth i9S TWS",
        price: "39.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Caractéristiques Principales :</b><br />
<br />
- 🎛️ <b>Contrôle Tactile Complet :</b> Abandonnez les boutons traditionnels. Touchez simplement pour contrôler vos écouteurs : répondre/terminer/rejeter un appel, lire/mettre en pause, ajuster le volume et activer l'assistant vocal.<br />
- 🔋 <b>Batterie Rechargeable Intégrée :</b> Profitez d'une transmission Bluetooth stable, d'une faible consommation d'énergie et d'une compatibilité renforcée. Livré avec un étui de chargement de 300 mAh pour un chargement mobile facile et pratique.<br />
- 🎶 <b>Son Surround Haute Définition :</b> Profitez d'un son surround de haute qualité avec une fonction de réduction de bruit pour une expérience musicale immersive.<br />
<br />
<b>✨ Avantages :</b><br />
<br />
- 🏃‍♂️ <b>Conception Ergonomique :</b> Offre un effet auditif stable et confortable, parfait pour le sport ou la marche. Idéal pour la course ou d'autres activités physiques.<br />
- 📱 <b>Compatibilité Universelle :</b> Fonctionne parfaitement avec la plupart des téléphones et tablettes, vous offrant une flexibilité maximale.<br />
- 🧳 <b>Facilité de Stockage et de Chargement :</b> L'étui de chargement compact de 300 mAh facilite le stockage et permet de recharger vos écouteurs en déplacement.<br />
<br />
<b>📦 Contenu du Produit :</b><br />
- Écouteurs Sans Fil<br />
- Étui de Chargement 300 mAh<br />
- Câble de Chargement<br />
- Manuel d'Utilisation<br />
<br />
<b>💡 Pourquoi Vous L'Adorerez :</b><br />
<br />
- <b>Confort et Stabilité :</b> Profitez d'un port confortable et stable grâce à une conception ergonomique adaptée à toutes vos activités.<br />
- <b>Contrôle Intuitif :</b> Gérez facilement vos appels, votre musique et votre assistant vocal avec un contrôle tactile complet.<br />
- <b>Qualité Audio Supérieure :</b> Plongez dans un son surround haute définition avec réduction de bruit pour une expérience audio inégalée.<br />
<br />
Optez pour nos écouteurs sans fil et profitez d'une liberté musicale totale avec un confort et une qualité audio exceptionnels ! 🎧✨<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,
        category: "electronique"

    },
    {_id: "1130",
        img: lenovo,
        images: [lenovo,lenovo3,lenovo2,lenovo4, ],
        promo: 30,
        productName: "Ecouteurs sans fil Lenovo LP40 PRO",
        price: "40.00",
        color: "noir",
        badge: true,
        des: `
<b>🎧 Découvrez les écouteurs sans fil parfaits :</b><br />
<br />
À la recherche des écouteurs sans fil parfaits avec des fonctionnalités de premier ordre ? Ne cherchez plus, car ces écouteurs sans fil ont tout ce qu'il vous faut ! Avec une <b>technologie Bluetooth 5.1 avancée</b> et des <b>commandes tactiles intuitives</b>, ils sont le compagnon audio ultime.<br />
<br />
<b>✨ Caractéristiques Impressionnantes :</b><br />
<br />
- 🔋 <b>Autonomie Prolongée :</b> Profitez d’une autonomie exceptionnelle grâce à un boîtier de charge de 300 mAh, offrant jusqu'à 4 heures d'utilisation continue et 150 heures en veille.<br />
- 🎨 <b>Design Élégant :</b> Combinez style et performance avec un design moderne et raffiné qui attire les regards.<br />
- 🛠️ <b>Puce Jerry AC6973 :</b> Bénéficiez d'une expérience sonore exceptionnelle grâce à cette technologie de pointe, conçue pour offrir une qualité audio irréprochable.<br />
<br />
<b>Pourquoi les choisir ?</b><br />
- Une connectivité stable et rapide.<br />
- Une ergonomie pensée pour un confort maximal.<br />
- Une combinaison parfaite entre technologie et style.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique",

    },
    {_id: "19931",
        img: lenovob1,
        images: [lenovob3,lenovob4, lenovob,lenovob1,lenovob2, ],
        promo: 30,
        productName: "Ecouteur sans fil Lenovo LP40 PRO",
        price: "40.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 À la recherche des écouteurs sans fil parfaits ?</b><br />
<br />
Ne cherchez plus ! Ces écouteurs sans fil sont conçus pour offrir des <b>performances de premier ordre</b> et un confort exceptionnel. Grâce à leur <b>technologie Bluetooth 5.1 avancée</b> et leurs <b>commandes tactiles intuitives</b>, ils sont le compagnon audio ultime.<br />
<br />
<b>✨ Fonctionnalités Impressionnantes :</b><br />
<br />
- 🔋 <b>Autonomie Prolongée :</b> Profitez d'une autonomie exceptionnelle grâce à un boîtier de charge de 300 mAh, offrant jusqu'à 4 heures d'utilisation continue et 150 heures en veille.<br />
- 🎨 <b>Design Élégant :</b> Un design moderne et stylé qui allie performance et esthétique.<br />
- 🛠️ <b>Puce Jerry AC6973 :</b> Dotée de la dernière technologie audio, cette puce offre une qualité sonore exceptionnelle à chaque utilisation.<br />
<br />
<b>Pourquoi les choisir ?</b><br />
<br />
- 🌐 Connectivité stable et rapide grâce au Bluetooth 5.1.<br />
- ✅ Commandes tactiles intuitives pour une expérience utilisateur fluide.<br />
- ✨ Une conception moderne et ergonomique pour un confort optimal.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },


    {_id: "4",
        img: inkaxT031,
        images: [inkaxT031,inkaxT033,inkaxT032,inkaxT034,inkaxT035,],
        promo: 20,
        productName: "Ecouteur Inkax T3",
        price: "46.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Caractéristiques Principales :</b><br />
<br />
- 🔗 <b>Bluetooth 5.0 :</b> Connexion rapide et stable à tous les appareils compatibles pour une transmission audio fluide et sans perte.<br />
- 🎮 <b>Mode Jeu :</b> Réduction de latence pour une immersion optimale dans vos jeux préférés.<br />
- 💧 <b>Design Ergonomique :</b> Confortable à porter, résistant à la transpiration et à l'eau, parfait pour les sessions prolongées ou l'entraînement.<br />
- 🎶 <b>Son Cristallin :</b> Profitez de basses profondes et d'aigus clairs pour une expérience sonore riche et immersive.<br />
- 🔇 <b>Réduction de Bruit CVC 6.0 :</b> Filtre efficacement les bruits de fond pour des appels et une écoute de haute qualité.<br />
- 🔋 <b>Autonomie Longue Durée :</b> Utilisez vos écouteurs toute la journée grâce à l’étui de chargement portable inclus.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },
    {_id: "3",
        img: inkax1,
        images: [  inkax1,inkax5,inkax3,inkax4,inkax2,],
        promo: 20,
        productName: "Ecouteur Inkax TWS-07",
        price: "49.00",
        color: "blanc",
        badge: true,
        des: `
<b>✨ Caractéristiques Principales :</b><br />
<br />
- 🔗 <b>Bluetooth 5.0 :</b> Connexion rapide et stable à tous les appareils compatibles pour une transmission audio fluide et sans perte.<br />
- 🎮 <b>Mode Jeu :</b> Réduction de latence pour une immersion optimale dans les jeux, offrant une expérience sans décalage.<br />
- 💧 <b>Design Ergonomique :</b> Conception confortable, résistante à la transpiration et à l'eau, parfaite pour des sessions prolongées ou pour l’entraînement.<br />
- 🎶 <b>Son Cristallin :</b> Profitez de basses profondes et d’aigus clairs, pour une expérience sonore riche et immersive.<br />
- 🔇 <b>Réduction de Bruit CVC 6.0 :</b> Filtre efficacement les bruits de fond pour des appels d'une clarté exceptionnelle.<br />
- 🔋 <b>Autonomie Longue Durée :</b> Profitez de longues heures d'utilisation grâce à l’étui de chargement portable inclus.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },
    {_id: "5",
        img:bt1 ,
        images: [bt1,bt2,bt3,bt4,bt5,],
        promo: 20,
        productName: "Écouteurs Bluetooth BT-35 - Game Mode",
        price: "59.00",
        color: "noir,rose",
        badge: true,
        des: `
<b>✨ Les Écouteurs Bluetooth BT-35 :</b><br />
<br />
Les écouteurs BT-35 offrent une expérience d'écoute exceptionnelle, conçus pour allier qualité audio et praticité.<br />
Dotés de la technologie Bluetooth, ils garantissent une connexion rapide et stable à votre appareil, idéaux pour vos déplacements quotidiens ou voyages.<br />
<br />

<b>🌟 Caractéristiques Principales :</b><br />
- 🎧 <b>Son de Qualité Supérieure :</b> Haut-parleurs de haute précision pour un son stéréo HiFi clair et immersif.<br />
- 🔇 <b>Suppression du Bruit ENC :</b> Réduction des bruits de fond pour des appels et une musique sans distraction.<br />
- 🔋 <b>Autonomie Exceptionnelle :</b> Jusqu'à 5 heures d'écoute continue, avec 20 heures supplémentaires grâce au boîtier de recharge compact inclus.<br />
- ⚡ <b>Charge Rapide :</b> Rechargez vos écouteurs en un rien de temps pour une utilisation prolongée.<br />
- 🕹️ <b>Compatibilité de Jeu :</b> Mode jeu pour une latence réduite et une immersion totale.<br />
- 🌊 <b>Résistants à l’Eau et à la Sueur :</b> Parfaits pour vos entraînements et activités sportives.<br />
- 🖱️ <b>Contrôle Tactile :</b> Navigation facile avec un simple toucher pour appairer, lire, mettre en pause ou répondre à un appel.<br />
- 🔢 <b>Affichage à LED :</b> Boîtier de charge avec affichage numérique pour surveiller le niveau de batterie.<br />
<br />

<b>🚀 Pourquoi Choisir les BT-35 ?</b><br />
Avec leur design ergonomique, les écouteurs BT-35 assurent un confort optimal même après de longues heures d'utilisation.<br />
Profitez d’une expérience audio complète et sans compromis, où que vous soyez !<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },

    {_id: "2",
        img: A1,
        images: [  A3,A2,A4,A5,A1,A6,],
        promo: 20,
        productName: "Ecouteur Inkax A9 Pro avec Afficheur",
        price: "69.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Ecouteur Bluetooth Inkax ENC avec Écran Tactile Blanc ✨</b><br />
<br />

<b>🌟 Contrôle de l'Écran Tactile Futuriste</b><br />
Naviguez sans effort grâce à l'écran intelligent en couleur intégré et à l'écran tactile. Ajustez vos paramètres, modifiez le volume ou répondez à vos appels avec quelques tapotements. Une interface conviviale pour une expérience audio intuitive et moderne.<br />
<br />

<b>🔇 Annulation de Bruit Supérieure</b><br />
Profitez d'une immersion totale grâce à la technologie avancée ANC (Annulation Active du Bruit). L'A9 Pro bloque efficacement les bruits de fond pour une écoute claire et sans distraction, idéale pour la musique, les appels ou le travail.<br />
<br />

<b>🔋 Durée de Vie de la Batterie Impressionnante</b><br />
Restez connecté toute la journée : jusqu'à 8 heures d'utilisation continue sur une seule charge, et jusqu'à 24 heures avec le boîtier de charge inclus. Une autonomie fiable pour répondre à tous vos besoins.<br />
<br />

<b>👂 Ajustement Confortable et Sécurisé</b><br />
Avec sa conception ergonomique et ses options d'écouteurs avancées, l'A9 Pro offre un ajustement parfait et sécurisé pour vos oreilles. Un confort optimal, même après de longues heures d'utilisation.<br />
<br />

<b>✨ Pourquoi Choisir l'A9 Pro ?</b><br />
L'A9 Pro allie technologie avancée, design élégant et fonctionnalités performantes pour une expérience audio exceptionnelle. Que vous soyez en déplacement, au travail ou à la maison, il est conçu pour répondre à vos exigences les plus élevées.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },







];


// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },


];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============



export const SplOfferData = [
    {
        _id: "1138",
        img: LampeCrystal5,
        images: [  LampeCrystal5,LampeCrystal2,LampeCrystal3,LampeCrystal4,LampeCrystal,],
        promo: 30,
        productName: "Lampe Crystal LED Tactile",
        price: "35.00",
        color: "Crystal",
        badge: true,
        des: " \"Lampe Diamant : L'élégance lumineuse avec contrôle tactile pour styler votre intérieur !\" ✨\n" +
            "💎 Design élégant en forme de diamant, idéal pour toutes vos pièces.\n" +
            "💡 3 couleurs de lumière au choix : blanche, jaune, et chaude, contrôlées par un simple toucher.\n" +
            "🔋 Batterie ultra-puissante : jusqu’à 10 heures d’autonomie, rechargeable en USB.\n" +
            "✨ Lumière éclatante et chic, parfaite pour votre maison !\n" +
            "\n" +
            "👉 Parfaite pour la chambre, le salon ou toute autre ambiance cosy. Commandez dès maintenant ! ",
        category:"decoration"
    },

    {_id: "1139",
        img: LampeDiamant2,
        images: [ LampeDiamant,LampeDiamant2,LampeDiamant3,LampeDiamant4,LampeDiamant5, ],
        promo: 30,
        productName: "Lampe Diamant LED a Commande",
        price: "35.00",
        color: "Crystal",
        badge: true,
        des : "Caractéristiques :\n" +
            "\n" +
            "Design Brillant : Lampe de chevet avec un design unique en forme de diamant, combinant simplicité et luxe moderne. Lumière élégante et douce, non éblouissante.\n" +
            "Sources de Lumière Multiples : 3 températures de couleur réglables via un interrupteur tactile, offrant une lumière délicate et naturelle, idéale pour la santé de votre famille.\n" +
            "Dimming Tactile Progressif : Sensibilité de lumière ajustable (lumière jaune, blanche, chaude) pour s'adapter à vos besoins.\n" +
            "Indicateur de Charge : Batterie rechargeable de 2000mAh offrant jusqu'à 10+ heures d'autonomie, avec charge USB sécurisée et arrêt automatique une fois pleine.\n" +
            "Corps Transparent : Fabriqué en acrylique de haute qualité avec un design prismatique multifacette, diffusant une lumière éclatante comme un diamant.\n" +
            "Spécifications :\n" +
            "\n" +
            "Matériau : Acrylique et PC\n" +
            "Commande : Capteur tactile\n" +
            "Charge : Batterie rechargeable 2000mAh, 8-10 heures d'éclairage continu\n" +
            "Couleurs de lumière : 3 (blanc, jaune, chaud) ou 16 couleurs RGB (contrôle par télécommande)\n" +
            "Puissance : 3W\n" +
            "Voltage : 5V\n" +
            "Applications : Salon, table de chevet, chambre à coucher\n" +
            "Inclus dans le paquet :\n" +
            "\n" +
            "1 lampe de bureau\n" +
            "1 câble de charge USB",
        category:"decoration"

    },
    {_id: "1123",
        img: support3,
        images: [ support2, support3],
        promo: 50,
        productName: "Support de Rangement à Ventouse",
        price: "36.00",
        color: "blanc",
        badge: true,
        des :"Transformez votre espace avec le Sucker Storage Rack, une solution pratique \n" +
            "et élégante pour organiser vos objets du quotidien sans perçage ni outils. \n" +
            "Parfait pour les salles de bains, cuisines, garages et plus encore, ce support \n" +
            "polyvalent s’adapte à toutes vos surfaces lisses comme le verre, le carrelage ou \n" +
            "le métal.\n" +
            " Caractéristiques Principales :\n" +
            " Fixation par Ventouse \n" +
            ": Grâce à son système de ventouses puissantes, \n" +
            "ce support s'accroche solidement sans nécessiter de perçage, de clous ou \n" +
            "de vis. Adhère facilement sur des surfaces lisses pour un rangement \n" +
            "pratique et sans dommages.\n" +
            " Matériau Résistant \n" +
            ": Fabriqué en plastique robuste, acier inoxydable \n" +
            "ou aluminium, ce support résiste à la rouille et à la corrosion, parfait pour \n" +
            "les environnements humides comme les salles de bains.\n" +
            " Installation Ultra Facile \n" +
            ": Aucune compétence nécessaire ! Appuyez \n" +
            "simplement sur les ventouses pour les fixer en un instant.\n" +
            " Portabilité et Flexibilité \n" +
            ": Déplacez-le facilement sans laisser de traces \n" +
            "ni de trous. Idéal pour les locataires ou pour réorganiser l'espace selon vos \n" +
            "besoins.\n" +
            " Grande Capacité de Charge \n" +
            ": Peut supporter plusieurs kilogrammes, \n" +
            "parfait pour ranger produits de salle de bain, épices de cuisine, ou même \n" +
            "outils légers.\n" +
            " Utilisation Polyvalente \n" +
            ": Que ce soit pour organiser vos accessoires de \n" +
            "salle de bain, vos ustensiles de cuisine, ou même des objets dans votre \n" +
            "garage, ce support s’adapte à vos besoins.\n" +
            " Design Compact et Élégant \n" +
            ": Conçu pour maximiser l'espace sans \n" +
            "encombrer. Discret et moderne, il s'intègre parfaitement à tout style de \n" +
            "décoration.\n" +
            " Résistance à l'Humidité \n" +
            ": Conçu pour résister aux environnements \n" +
            "humides, ce support est idéal pour une utilisation dans des salles de bains \n" +
            "ou cuisines, gardant vos objets au sec et en sécurité.\n" +
            " Réutilisable et Durable \n" +
            ": Les ventouses peuvent être réutilisées \n" +
            "plusieurs fois, il suffit de les nettoyer ou de les réhumidifier pour une \n" +
            "adhérence renouvelée.\n" +
            " Optez pour le Sucker Storage Rack et profitez d'un rangement pratique et \n" +
            "sans souci pour tous vos espaces",
        category: "maison"

    },
    {_id: "1128",
        img: sandrier,
        images: [sandrier,sandrier2,sandrier3,sandrier4,sandrier5,sandrier6,
        ],
        promo: 20,
        productName: "Cendrier auto-extinguible",
        price: "37.00",
        color: "vert",
        badge: true,
        des : "Cendrier sans fumée multifonction : En tant que cendrier pour la maison, il dispose de 4 petits renfoncements pour tenir les cigarettes. Le bas est équipé d'une éponge filtrante capable de filtrer la plupart des gaz, il suffit d'ajouter une pastille d'aromathérapie à l'intérieur pour l'utiliser comme un diffuseur d'arômes.\n" +

            "Matériau ignifuge de haute qualité : La doublure du cendrier pour cigarettes en intérieur est fabriquée à partir de matériaux ignifuges résistants aux hautes températures, éliminant les risques de sécurité, prolongeant la durée de vie du produit et le rendant plus durable.\n" +

            "Alimentation Simple & Facilité d'Utilisation : Notre cendrier nécessite 2 piles AA pour fonctionner environ 40 fois, pas besoin de le recharger. Convient à une utilisation partout, y compris dans les chambres à coucher et les bureaux (les piles ne sont pas incluses).\n" +

            "Facilité de Nettoyage : La conception divisée rend le nettoyage plus pratique. Lorsque la doublure est couverte de suie, il suffit de la retirer et de la laver à l'eau. L'éponge filtrante intégrée peut également être nettoyée et remplacée (nécessite un séchage).\n" +

            "Fonction de minutage : Chaque séance dure 5 minutes et 20 secondes, ce qui convient à la plupart des habitudes, et vous n'avez pas à vous soucier d'oublier d'éteindre et de gaspiller de l'électricité.\n",
        category: "facilite"


    },
    {_id: "1122",
        img: lampeMurale5,
        images: [ lampeMurale, lampeMurale2, lampeMurale3, lampeMurale4, lampeMurale5, lampeMurale6,],
        promo: 30,
        productName: "Lampe murale solaire",
        price: "29.00",
        color: "bois",
        badge: true,
        des :
            " Technologie de capteur photoélectrique de première classe :\n" +
            " Cette lampe murale à capteur extérieur est équipée d’une batterie \n" +
            "rechargeable de 1200mAh.\n" +
            " Mode 1 (Lumière faible + Détection de mouvement) : éclaire \n" +
            "faiblement en permanence, puis intensifie la lumière en cas de \n" +
            "présence, et revient à une lumière faible en l'absence de mouvement.\n" +
            " Mode 2 (Détection de mouvement uniquement) : s’active à pleine \n" +
            "intensité en cas de présence et s’éteint en l’absence de mouvement. \n" +
            "Ce mode permet une autonomie prolongée selon la fréquence de \n" +
            "détection.\n" +
            " Mode 3 (Éclairage constant) : fournit un éclairage continu pendant \n" +
            "environ 2 à 3 heures, puis s'allume automatiquement en présence ou \n" +
            "absence de mouvement.\n" +
            " Cette lampe murale solaire offre une solution d'éclairage extérieur performante \n" +
            "et éco-énergétique, adaptée aux conditions extérieures grâce à son indice de \n" +
            "protection IP65.\n" +
            " Ampoules : 2 ampoules LED\n" +
            " Sortie produit : 5.5V 2W\n" +
            " Capacité de la batterie : 1200mAh\n" +
            " Poids du produit : 300g\n" +
            " Indice d’étanchéité : IP65\n" +
            " Luminosité : 600 lumens (LM)\n" +
            " Matériau : silicium polycristallin + plastique ABS + composants \n" +
            "électroniques\n" +
            " Distance de détection : Capteur infrarouge, 5 à 8 mètres\n" +
            " Dimensions du produit : 14,4 x 4,7 x 14,9 cm\n" +
            " Temps d’éclairage continu : 4 à 5 heures\n" +
            " Durée de vie : 10 000 heures",
        category: "decoration"


    },
    {_id: "1112",
        img: humidificateur,
        images: [humidificateur, humidificateur2, humidificateur3, humidificateur4, humidificateur5, humidificateur6, humidificateur7],
        promo: 50,
        productName: "humidificateur Éblouissant",
        price: "39.00",
        color: "noir , blanc",
        badge: true,
        des : "Cet humidificateur compact et éblouissant est bien plus qu'un simple appareil \n" +
            "de bureau; c'est une véritable œuvre d'art. Avec un réservoir d'eau de 400 ml, il \n" +
            "offre jusqu'à 6 heures d'humidification continue, répondant à tous vos besoins \n" +
            "en matière d'humidification. \n" +
            " Caractéristiques : \n" +
            " Capacité de 400 ml \n" +
            " : Le réservoir d'eau généreux permet jusqu'à 6 \n" +
            "heures d'utilisation continue, parfait pour une journée entière de travail ou \n" +"pour la nuit. \n" +
            " Mode Lumière Éblouissante \n" +
            " : Ce mode transforme l'humidificateur en \n" +
            "une pièce artistique. La lumière douce à sept couleurs crée une ambiance \n" +
            "apaisante et relaxante, tandis que la lumière blanche est idéale pour être \n" +
            "utilisée comme petite veilleuse. \n" +
            " Utilisation Facile \n" +
            " : Le produit ne peut être utilisé qu'après avoir ajouté \n" +
            "de l'eau. Assurez-vous de débrancher l'appareil avant de le remplir. \n" +
            " Transformez votre espace de travail ou de repos en un environnement plus \n" +
            "agréable et relaxant avec cet humidificateur élégant et fonctionnel",
        category: "facilite",

    },
    {
        _id: "1110",
        img: destributeurD1,
        images: [destributeurD1, destributeurD2, destributeurD3, destributeurD4],
        promo: 30,
        productName: " Distributeur de Dentifrice Automatique",
        price: "45.00",
        color: "noir",
        badge: true,
        des : "sans dégâts, adapté aux enfants: Si vous avez des enfants qui\n" +
            "commencent à se brosser les dents seuls, ce distributeur automatique de\n" +
            "dentifrice vous facilitera la vie ! Plus besoin de nettoyer du dentifrice sur les\n" +
            "murs et le plafond. Ce distributeur aide à minimiser les gâchis et à économiser\n" +
            "du dentifrice.\n" +
            "Économique & Facile à Utiliser: Équipé d'un porte \n" +
            "brosse mural pratique cet appareil est simple d’utilisation pour les petits comme les grands. Il permet\n" +
            "de contrôler facilement la quantité de dentifrice pour éviter le gaspillage.\n" +
            "2 Gobelets de Rinçage et 4 Emplacements pour Brosses: Le design\n" +
            "astucieux des gobelets à rinçage renversés assure qu'ils s'égouttent\n" +
            "correctement. Le support peut contenir jusqu'à 4 brosses à dents, y compris\n" +
            "les brosses électriques , pour adultes et enfants.\n" +
            "Installation Facile: Pas besoin de percer ou de faire des trous dans vos\n" +
            "murs ! Nettoyez simplement la surface, collez la bande adhésive, et attendez\n" +
            "24 heures avant de l'utiliser. Convient aux surfaces lisses comme le carrelage,\n" +
            "le marbre, le verre et le métal.\n" +
            "Matériau de Qualité Alimentaire: Fabriqué en ABS sans odeur, ce\n" +
            "distributeur garantit une utilisation en toute sécurité, même pour les gobelets\n" +
            "de rinçage qui résistent à de hautes températures.\n" +
            "Entretien Facile: Ce distributeur de dentifrice est facile à démonter et à\n" +
            "nettoyer. Il s’adapte aux tubes de dentifrice avec un diamètre d'ouverture de\n" +
            "0,55 pouces ou moins, assurant une propreté et une hygiène parfaites.\n" +
            "Apportez de l’ordre à votre routine quotidienne avec ce distributeur de\n" +
            "dentifrice pratique et élégant !",
    },

    {
        _id: "1104",
        img: porteSac3,
        images: [porteSac3, porteSac, porteSac2,  porteSac4],
        productName: "Porte-Sac à Main Élégant",
        price: "49.00",
        promo: 30,
        color: "blanc,gris,noir",
        badge: true,
        des: "Porte-Sac à Main Élégant – Organisation, gain d’espace et Protection pour \n" +
            "vos Sacs Précieux\n" +
            " Découvrez en exclusivité notre tout nouveau porte-sac à main, un accessoire \n" +
            "haut de gamme conçu pour optimiser l'organisation de vos sacs tout en \n" +
            "économisant de l'espace. Spécialement conçu pour allier praticité et élégance, \n" +
            "ce porte-sac est parfait pour celles et ceux qui souhaitent un rangement \n" +
            "ordonné et stylé.\n" +
            " Les Points Forts de Notre Porte-Sac\n" +

            " Gain d’Espace Optimal : Libérez de la place dans vos placards et rangez \n" +
            "vos sacs de manière intelligente et organisée. Ce porte-sac est idéal pour \n" +
            "maximiser l'espace, même dans les petits appartements !\n" +
            " Protection et Style : Préservez vos sacs de l’usure et de la poussière tout \n" +
            "en les exposant avec élégance. Ce porte-sac protège vos accessoires de \n" +
            "valeur et vous permet d’y accéder facilement, sans encombrement.\n" +
            " Organisation Professionnelle : Transformez votre espace avec un \n" +
            "accessoire qui allie luxe et fonctionnalité. Grâce à ce porte-sac, vos sacs \n" +
            "ne seront plus jamais empilés ou rangés au hasard.\n" +
            " Profitez de Notre Promotion de Lancement\n" +
            " Pour célébrer cette nouveauté, bénéficiez d’une offre spéciale de lancement ! \n" +
            "Achetez-en deux pour une remise exclusive et optimisez votre espace de \n" +
            "rangement tout en valorisant votre collection de sacs. Attention, cette offre \n" +
            "limitée n’est disponible que pour une durée restreinte !\n" +
            " Ce porte-sac incarne l’innovation en matière de rangement et de design, parfait \n" +
            "pour organiser et mettre en valeur vos sacs tout en gagnant de l'espace. Ne \n" +
            "manquez pas l’occasion d’obtenir ce produit exclusif et de profiter de notre \n" +
            "promotion de lancement  Contenu de l’emballage : 1 support de rangement (peut contenir 6 sacs).\n" +
            "Couleur : Transparent, noir.\n" +
            "Dimensions approximatives : 35 x 30 x 100cm.\n" +
            "Matériaux : oxford 600 D impermeable et PVC transparent.",
        category: "facilite",
    },

    {
        _id: "1111",
        img: friteuse,
        images: [friteuse, friteuse2, friteuse3, friteuse4, friteuse5,friteuse6, friteuse7, friteuse8,],
        promo: 25,
        productName: "Friteuse à Air Ultrean",
        price: "149.00",
        color: "noir",
        badge: true,
        des : "Friteuse à Air Ultrean, la solution idéale pour des repas sains et savoureux ! \n" +
            "Découvrez la Friteuse à Air Ultrean, l'appareil de cuisine qui révolutionne la \n" +
            "façon dont vous préparez vos repas. Profitez de plats croustillants à l’extérieur \n" +
            "et tendres à l’intérieur avec jusqu’à 85 % de matières grasses en \n" +
            "moins grâce à la technologie avancée de circulation d’air rapide à 360°!\n" +
            "Caractéristiques :\n" +
            "Cuisson Saine :\n" +
            "Fritez de manière plus saine avec moins d’huile tout en savourant vos \n" +
            "aliments préférés.\n" +
            "Multifonctionnelle et Facile à Utiliser :\n" +
            "Équipée d’un écran tactile numérique, cette friteuse propose 10 \n" +
            "préréglages pour une cuisson parfaite :\n" +
            "Toast, Chips, Chips congelées, Cuisses de poulet, Crevettes, Steak, \n" +
            "Poisson, Pizza, Gâteau.\n" +
            "Personnalisez facilement les réglages pour cuire vos aliments exactement \n" +
            "comme vous le souhaitez.\n" +
            "Facile à Nettoyer et Sécurisée :\n" +
            "Panier et marmite à frites non collants et faciles à nettoyer, avec pièces \n" +
            "amovibles compatibles avec le lave-vaisselle.\n" +
            "Équipée d’un arrêt automatique pour éviter la surchauffe ; sans PFOA ni \n" +
            "BPA pour une sécurité accrue.\n" +
            "Design Contemporain et Élégant :\n" +
            "Finition mate sur le dessus réduisant les traces de doigts et les rayures.\n" +
            "Grande capacité de 6L, idéale pour servir 3 à 5 personnes.\n" +
            "Livrée avec un fabuleux livre de recettes bonus, vous offrant des recettes \n" +
            "délicieuses et faciles pour vos repas quotidiens.\n" +
            "Spécifications :\n" +

            " Marque : TESKA\n" +
            " Puissance : 2400W\n" +
            " Contenance : 6L ",
        category: "maison",
    },

    {
        _id: "1106",
        img: etagiereDouble,
        images: [etagiereDouble, etagiereDouble2, etagiereDouble3, etagiereDouble4, etagiereDouble5, etagiereDouble6],
        promo: 20,
        productName: "Étagère Double",
        price: "59.00",
        color: "bois",
        badge: true,

        des :"  Étagère DOUBLE murale suspendue en bois rustique avec corde\n" +
            "Cette étagère double murale suspendue en bois rustique, soutenue par des\n" +
            "cordes, offre à la fois style et fonctionnalité pour différents espaces intérieurs.\n" +
            "Idéale pour le rangement de plantes, livres et objets décoratifs, elle s'intègre \n" +
            "parfaitement dans des ambiances variées telles que maritime, rétro ou\n" +
            "campagne.\n" +
            "Points clés :\n" +

            " Design multifonctionnel : Parfaite pour exposer des plantes, des livres ou\n" +
            "des objets décoratifs.\n" +
            " Matériaux de qualité : Fabriquée en bois de paulownia léger et résistant,\n" +
            "avec des cordes en jute.\n" +
            " Installation facile : Déjà assemblée, elle se fixe facilement au mur ou au\n" +
            "plafond grâce à des crochets inoxydables.\n" +
            " Économie d'espace : Offre un espace de rangement vertical \n" +
            "supplémentaire tout en étant esthétique.\n" +
            " Idée cadeau : Un cadeau créatif et pratique pour diverses occasions.\n" +
            " Ce produit combine l'utilité à l'esthétique, tout en étant simple à installer et\n" +
            "adaptable à différents types d'intérieurs.",
    },
    {
        _id: "1107",
        img: etagiereLumineuse,
        images: [etagiereLumineuse, etagiereLumineuse2, etagiereLumineuse3 , etagiereLumineuse4, etagiereLumineuse5],
        productName: "Étagère Lumineuse",
        price: "49.00",
        promo: 20,
        color: "bois",
        badge: true,
        des: "Étagère Murale en Corde de Chanvre avec Guirlandes Lumineuses – \n" +
            "Créez une Atmosphère Chaleureuse et Romantique ! \n" +
            "Description :\n" +
            " Ajoutez une touche de charme naturel à votre intérieur avec notre étagère \n" +
            "murale en corde de chanvre, décorée de guirlandes lumineuses. Cette pièce \n" +
            "unique crée une ambiance chaleureuse et accueillante, parfaite pour toute \n" +
            "maison.\n" +
            " Caractéristiques Principales :\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " Conception Élégante : L'étagère est enveloppée de bois naturel et ornée \n" +
            "de guirlandes lumineuses sur une ficelle suspendue, ajoutant une ambiance \n" +
            "romantique à votre espace. \n" +
            "Détails Dimensionnels : Avec des dimensions d'environ 40 x 13 x 1,3 cm, \n" +
            "cette étagère est suffisamment compacte pour s'intégrer harmonieusement \n" +
            "sans prendre trop d'espace d'affichage. \n" +
            "Installation Facile : Installez-la en un rien de temps : passez la corde à \n" +
            "travers les trous de l'étagère, enroulez la guirlande lumineuse LED et la \n" +
            "guirlande de lierre artificiel autour, puis accrochez-la où vous le souhaitez. \n" +
            "Simple et pratique ! \n" +
            "Décoration de Chambre Chic : Non seulement elle expose des plantes, \n" +
            "des souvenirs d'art et des livres, mais elle transforme également votre \n" +
            "maison en un jardin botanique. Avec une verdure élégante et des lumières \n" +
            "LED, cette étagère est le mélange parfait de style rustique, bohème et \n" +
            "sophistiqué. \n" +
            "Polyvalence : Idéale pour contenir des micro-paysages de plantes \n" +
            "d'intérieur, des souvenirs, et des décorations artistiques. C'est la \n" +
            "décoration parfaite pour votre salon, chambre, salle de bain, ferme et \n" +
            "cuisine. Un cadeau idéal pour vos proches ! \n" +
            "Embellissez votre espace avec cette étagère murale en corde de \n" +
            "chanvre, alliant fonctionnalité et esthétique. Parfaite pour ceux qui cherchent à \n" +
            "créer une atmosphère unique et accueillante chez vous",
        category: "decoration",
    },
    {
        _id: "1109",
        img: guirlandes8,
        images: [guirlandes8 , guirlandes, guirlandes2, guirlandes3, guirlandes4, guirlandes5, guirlandes6, guirlandes7, guirlandes9],
        promo: 20,
        productName: "Guirlande lumineuse - 10M",
        price: "63.00",
        color: "8 modes",
        badge: true,
        des : "Guirlande lumineuse de feuilles vertes de lierre artificiel - 10M\n" +
            "\n" +
            "\n" +
            "\n" +
            "Cette guirlande lumineuse LED avec des feuilles de lierre artificielles réalistes est idéale pour décorer différents espaces, tels que les chambres, jardins, balcons, mariages, et événements festifs. Elle offre une lumière agréable et crée des ambiances variées, tant de jour que de nuit.\n" +
            "Points clés :\n" +
            "* Polyvalence décorative : Utilisable pour la décoration intérieure et extérieure, notamment pour les mariages, les fêtes, les jardins ou comme lampe murale.\n" +
            "* Matériau durable : Les feuilles en soie et les tiges en plastique résistent aux intempéries et ne se décolorent pas facilement.\n" +
            "* 8 modes de lumière LED : Offrant une grande variété d’effets lumineux pour s'adapter à toutes les occasions.\n" +
            "* Facilité d'installation : Facile à suspendre et à démonter.\n" +
            "* Dimensions et accessoires : \n" +
            "Chaque lierre mesure environ 2,5 m de long, un sachet de 4 guirlandes de lierre artificielle, soit un total de 84 pieds, 72 feuilles, diamètre d'environ 1,37 pouces. Les feuilles sont en soie et les tiges en plastique\n",
    },


];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
    {_id: "1143",
        img: miniShaver,
        images: [ miniShaver,miniShaver2,miniShaver3,miniShaver4,],
        promo: 40,
        productName: "Mini Tondeuse Sans Fil",
        price: "32.00",
        color: "argent,bleu",
        badge: true,
        des: `Mini Tondeuse Sans Fil : La solution idéale pour une coupe rapide et précise, où que vous soyez !<br /><br />

<b>Caractéristiques :</b><br />
- <b>Lame en Acier Inoxydable :</b> Conçue pour une coupe précise et sans effort, assurant un résultat impeccable sans frottement ni irritation, contrairement aux lames classiques comme Gilette.<br />
- <b>Design Compact et Léger :</b> Facile à transporter et à utiliser, idéale pour les voyages et les retouches rapides.<br />
- <b>Batterie Rechargeable :</b> Temps de charge de 8 heures pour une autonomie d’environ 60 minutes.<br />
- <b>Fonctionnement Sans Fil :</b> Permet une utilisation pratique et sans encombrement, offrant une liberté totale de mouvement.<br />
- <b>Nettoyage Facile :</b> Lames détachables pour un nettoyage rapide et hygiénique.<br />
- <b>Usage Polyvalent :</b> Convient aussi bien aux hommes qu’aux femmes, et s’adapte aux parties intimes grâce à une coupe efficace et douce, sans nécessiter trop d’allers-retours.<br />
- <b>Poids :</b> 50 g<br />
- <b>Matériaux :</b> Plastique, acier inoxydable, composants électroniques<br /><br />

<b>Obtenez une coupe parfaite en un clin d’œil</b><br />
Avec la Mini Tondeuse Sans Fil, profitez d’un accessoire indispensable pour un style impeccable en toutes circonstances. Compacte, légère, et facile à nettoyer, elle vous accompagne partout pour une expérience de coupe rapide et agréable.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,
        category: "facilite"



    },
    {_id: "1132",
        img: tendeuse2,
        images: [ tendeuse,tendeuse2,tendeuse3,],
        promo: 30,
        productName: "Tondeuse cheveux 3en1 Rechargeable - Kaiwei Z-316",
        price: "35.00",
        color: "vert et noir",
        badge: true,
        des : "\n" +
            "Obtenez un style personnalisé avec cette tondeuse multifonction 3 en 1 rechargeable, parfaite pour sculpter votre barbe, entretenir vos cheveux et nettoyer les zones difficiles d’accès comme le nez et les oreilles.\n" +
            "\n" +
            "Caractéristiques :\n" +
            "Fonctions 3-en-1 :\n" +
            "Tondeuse cheveux avec lame universelle pour des coupes précises.\n" +
            "Tondeuse à barbe pour un style et un entretien de la barbe parfaits.\n" +
            "Tondeuse nez et oreilles pour un nettoyage rapide et sécurisé des zones sensibles.\n" +
            "Lames Confortables à Affûtage Automatique : Conçues pour une coupe en douceur, assurant confort et sécurité.\n" +
            "Design Ergonomique :\n" +
            "Assure une prise en main stable et agréable pour un usage facilité.\n" +
            "Rechargeable :\n" +
            "Temps de charge : 8 heures pour une utilisation d’environ 60 minutes.\n" +
            "Batterie incluse : Permet une utilisation sans fil pour plus de liberté de mouvement.\n" +
            "Nettoyage Facile :\n" +
            "Détachez facilement les lames et les peignes pour un nettoyage rapide et hygiénique.\n" +
            "Dimensions et Matériaux :\n" +
            "Hauteur : 17 cm\n" +
            "Longueur : 6 cm\n" +
            "Largeur : 4 cm\n" +
            "Poids : 150 g\n" +
            "Matériaux : Caoutchouc, plastique, acier inoxydable, composants électroniques"+
            "\n" +
            "صنع من أجل تجارة إلكترونية أفضل ❤️ShopyCloud | 2024 ©",
        category: "facilite"

    },
    {_id: "1137",
        img: carBleutooth,
        images: [carBleutooth,carBleutooth2,carBleutooth3,carBleutooth4, ],
        promo: 30,
        productName: "Car Bluetooth 5.0 FM Type-C Dual USB Charger",
        price: "15.00",
        color: "noir",
        badge: true,
        des : "1.BT V5.0\n" +
            "\n" +
            "2. Support ambient light\n" +
            "\n" +
            "3. Auto power off memory function\n" +
            "\n" +
            "4. Support FM stereo transmission technology\n" +
            "\n" +
            "5. Support TF card U disk playback\n" +
            "\n" +
            "6. Support WMA, MP3, WAV, FLAC music format files\n" +
            "\n" +
            "7. BT support A2DP, AVRCP, HFP, HSP\n" +
            "\n" +
            "8.BT support hands-free calls\n" +
            "\n" +
            "9. Support BT compatible stereo music DSP technology\n" +
            "\n" +
            "10. Type C + Dual USB smart charge\n" +
            "\n" +
            "صنع من أجل تجارة إلكترونية أفضل ❤️ShopyCloud | 2024 ©",
        category: "electronique"
    },
    {_id: "1136",
        img: peluche2,
        images: [  peluche2,peluche1,peluche3,peluche4,peluche5,peluche6,],
        promo: 30,
        productName: "Rasoir Anti-Peluches Rechargeable",
        price: "26.00",
        color: "vert",
        badge: true,
        des : "La solution idéale pour préserver vos vêtements et tissus en parfait état !\n" +
            "\n" +
            "Avantages principaux :\n" +
            "Puissance et efficacité :\n" +
            "Doté d'un moteur puissant, cet appareil élimine facilement et rapidement les peluches et bouloches, redonnant à vos tissus leur aspect neuf.\n" +
            "\n" +
            "Batterie rechargeable :\n" +
            "Conçu avec une batterie longue durée, il permet une utilisation fréquente sans avoir besoin de remplacer les piles.\n" +
            "\n" +
            "Design ergonomique et portable :\n" +
            "Compact et léger, il est facile à transporter, idéal pour un usage domestique ou en voyage.\n" +
            "\n" +
            "Accessoires multiples :\n" +
            "Équipé d'une tête de rasage spécialement conçue pour éliminer efficacement les peluches tenaces des vêtements, meubles et couvertures.\n" +
            "\n" +
            "Facilité d'utilisation :\n" +
            "Un design simple et pratique qui permet d'atteindre même les zones les plus étroites sans effort.\n" +
            "\n" +
            "Bénéfices :\n" +
            "Maintient vos vêtements propres et comme neufs.\n" +
            "Convient à tous types de tissus : laine, coton, lin, et plus encore.\n" +
            "Un outil indispensable à la maison ou en déplacement.\n" +
            "Commandez-le dès maintenant pour des tissus impeccables et élégants !" +
            "\n" +
            "صنع من أجل تجارة إلكترونية أفضل ❤️ShopyCloud | 2024 ©",
        category: "facilite"
    },


    {_id: "1128",
        img: sandrier,
        images: [sandrier,sandrier2,sandrier3,sandrier4,sandrier5,sandrier6,
        ],
        promo: 20,
        productName: "Cendrier auto-extinguible",
        price: "37.00",
        color: "vert",
        badge: true,
        des : "Cendrier sans fumée multifonction : En tant que cendrier pour la maison, il dispose de 4 petits renfoncements pour tenir les cigarettes. Le bas est équipé d'une éponge filtrante capable de filtrer la plupart des gaz, il suffit d'ajouter une pastille d'aromathérapie à l'intérieur pour l'utiliser comme un diffuseur d'arômes.\n" +

            "Matériau ignifuge de haute qualité : La doublure du cendrier pour cigarettes en intérieur est fabriquée à partir de matériaux ignifuges résistants aux hautes températures, éliminant les risques de sécurité, prolongeant la durée de vie du produit et le rendant plus durable.\n" +

            "Alimentation Simple & Facilité d'Utilisation : Notre cendrier nécessite 2 piles AA pour fonctionner environ 40 fois, pas besoin de le recharger. Convient à une utilisation partout, y compris dans les chambres à coucher et les bureaux (les piles ne sont pas incluses).\n" +

            "Facilité de Nettoyage : La conception divisée rend le nettoyage plus pratique. Lorsque la doublure est couverte de suie, il suffit de la retirer et de la laver à l'eau. L'éponge filtrante intégrée peut également être nettoyée et remplacée (nécessite un séchage).\n" +

            "Fonction de minutage : Chaque séance dure 5 minutes et 20 secondes, ce qui convient à la plupart des habitudes, et vous n'avez pas à vous soucier d'oublier d'éteindre et de gaspiller de l'électricité.\n",
        category: "facilite"


    },
    {_id: "1135",
        img: kit1,
        images: [kit3,kit4,kit5,kit1,kit2, ],
        promo: 50,
        productName: "Ecouteurs sans fil I12 TWS",
        price: "32.00",
        color: "blanc",
        badge: true,
        des: `Écouteurs Bluetooth V5.0 : Profitez d'une qualité sonore exceptionnelle et d'une expérience utilisateur fluide !<br /><br />

<b>Caractéristiques :</b><br />
- <b>Version Bluetooth :</b> Dual-mode Bluetooth V5.0 avec une ultra-basse consommation d'énergie.<br />
- <b>Protocoles supportés :</b> A2P1.3 / HFP1.6 / HSP1.2 / AVRCP1.6 / D11.3.<br />
- <b>Bande d'utilisation Bluetooth :</b> 2.4 GHz.<br />
- <b>Distance sans fil :</b> Jusqu'à 15 m.<br />
- <b>Poids :</b> Environ 4-5 g (par écouteur).<br />
- <b>Temps de charge :</b> 1 à 1,5 heure.<br />
- <b>Temps d'appel :</b> Environ 4 à 4,5 heures.<br />
- <b>Boîtier de charge :</b> Capacité de 300 mAh, mini et portable, permet plusieurs recharges des écouteurs.<br /><br />

<b>Points forts :</b><br />
- <b>Qualité audio exceptionnelle :</b> Bluetooth 5.0 offre une qualité sonore cristalline avec des aigus nets, même dans des environnements bruyants.<br />
- <b>Commandes vocales :</b> Affichage du niveau de batterie, commandes vocales et fonction Siri intégrée pour une expérience simplifiée.<br />
- <b>Paires automatiques :</b> Sortez les deux écouteurs du boîtier, ils s'allument et se connectent automatiquement entre eux, puis à votre appareil Bluetooth.<br />
- <b>Conception ergonomique :</b> Idéal pour le sport, la course, la randonnée, le vélo, le gymnase, etc., offrant une tenue stable et confortable.<br /><br />

<b>Pourquoi les choisir ?</b><br />
Ces écouteurs Bluetooth offrent une expérience pratique, stable et agréable pour toutes vos activités, avec une autonomie prolongée et une qualité sonore incomparable. Ne ratez pas l'opportunité de profiter d'un son haut de gamme où que vous soyez.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique",


    },
    {_id: "1",
        img: mx1,
        images: [mx3,mx5,mx4, mx2, mx1,],
        promo: 40,
        productName: "Ecouteur mx10 pro ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `Écouteurs sans fil : Une simplicité magique associée à une technologie avancée.<br /><br />

<b>Caractéristiques principales :</b><br />
- <b>Connexion instantanée :</b> Sortez-les du boîtier et ils se connectent immédiatement à vos appareils. Mettez-les dans vos oreilles, et ils sont prêts à l'emploi.<br />
- <b>Qualité d'appel exceptionnelle :</b> Les appels binauraux sont aussi clairs que des conversations en face à face, grâce à la réduction numérique du bruit.<br />
- <b>Effet sonore haute fidélité :</b> Conception dynamique de l'unité offrant une excellente analyse audio, sans distorsion, pour une expérience immersive inégalée.<br />
- <b>Assistant vocal Siri :</b> Appuyez longuement pendant 2 secondes pour activer Siri. Vérifiez la météo, demandez une navigation ou passez des appels avec facilité.<br />
- <b>Toucher 3D :</b> Écran tactile haute sensibilité intégré pour des commandes intuitives. Effectuez rapidement une variété d'opérations en touchant légèrement.<br />
- <b>Conception ergonomique :</b> Ajustement confortable pour un port prolongé sans gêne.<br /><br />

<b>Pourquoi les choisir ?</b><br />
Ces écouteurs allient simplicité et innovation. Avec leur connexion instantanée, leur excellente qualité sonore, et leur design ergonomique, ils vous accompagnent partout avec élégance et praticité.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },
    {_id: "9",
        img:MI1 ,
        images: [MI1,MI2,MI3,MI4,MI5,],
        promo: 20,
        productName: "Écouteurs  Bluetooth  Mi True ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques principales :</b><br /><br />
💡 <b>Légèreté et Confort :</b> Chaque écouteur ne pèse que 4,1 g, offrant un confort ultime même lors d'une utilisation prolongée.<br /><br />
🔋 <b>Autonomie Élevée :</b> Profitez d'environ 4 heures d'appels ou de musique avec une seule charge. Grâce à l'étui de charge de 300 mAh, bénéficiez d'une autonomie totale de 12 heures pour vos déplacements.<br /><br />
⚡ <b>Chargement Rapide :</b> Rechargez vos écouteurs en seulement 1,5 heure, et l'étui de charge en 2 heures, pour une utilisation continue tout au long de la journée.<br /><br />
📶 <b>Bluetooth 5.0 :</b> Profitez d'une connexion stable et rapide jusqu'à 10 mètres de distance, sans interruption, grâce à la technologie Bluetooth 5.0.<br /><br />
🎧 <b>Connexion Automatique :</b> Connectez vos écouteurs manuellement la première fois, puis laissez la magie opérer ! Les écouteurs se connectent automatiquement à votre appareil dès la sortie de l'étui.<br /><br />

<b>Spécifications Techniques :</b><br /><br />
- <b>Poids net d’un écouteur :</b> Environ 4,1 g<br />
- <b>Autonomie d’appel :</b> Environ 4 heures<br />
- <b>Capacité de la batterie des écouteurs :</b> 43 mAh chacun<br />
- <b>Capacité de la batterie de l’étui de charge :</b> 300 mAh<br />
- <b>Temps de charge des écouteurs :</b> Environ 1,5 heure<br />
- <b>Temps de charge de l’étui :</b> Environ 2 heures<br />
- <b>Connectivité sans fil :</b> Bluetooth 5.0<br />
- <b>Poids total de l’étui de charge :</b> Environ 35,4 g<br />
- <b>Portée de la communication :</b> 10 mètres (sans obstacle)<br />
- <b>Dimensions des écouteurs :</b> 26,65 × 16,4 × 21,6 mm<br />
- <b>Dimensions de l’étui de charge :</b> 62 x 40 x 27,2 mm<br />
- <b>Protocole Bluetooth :</b> AVRCP/HSP/HFP/A2DP<br /><br />

Avec les Mi True Wireless Earbuds, bénéficiez d'une liberté totale pour écouter votre musique, passer vos appels, et vous immerger dans une qualité sonore sans compromis. 🌟<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },


    {_id: "10",
        img:TWS1 ,
        images: [TWS2,TWS3,TWS4,TWS5,TWS1,],
        promo: 20,
        productName: "Écouteurs  Bluetooth TWS M88 ",
        price: "35.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques principales :</b><br /><br />
- 🌟 <b>Réduction Active du Bruit :</b> Bloquez les bruits indésirables et plongez dans votre musique grâce à la fonction de réduction active du bruit.<br />
- 📶 <b>Bluetooth 5.3 Sans Fil :</b> Profitez de connexions plus rapides et stables grâce à la dernière technologie Bluetooth 5.3.<br />
- 💡 <b>Affichage LED :</b> Suivez l'état de la batterie et de la charge grâce à l'affichage LED pratique sur l'étui de charge.<br />
- 💧 <b>Étanchéité :</b> Ne laissez pas la pluie ou la transpiration interrompre votre musique pendant vos entraînements ou activités en plein air.<br /><br />

<b>Description :</b><br />
M88 TWS, écouteurs sans fil Bluetooth avec boîtier de charge à LED, micro intégré et écouteurs résistants à l'eau. Idéal pour une expérience audio immersive et pratique.<br /><br />

<b>Contenu de l'emballage :</b><br />
- 1 paire d'écouteurs<br />
- 1 boîtier de charge<br />
- 1 câble<br />
- 1 manuel d'utilisation<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"
    },
    {_id: "8",
        img:M101 ,
        images: [ M101,M104,M105,M106,M102,M103,],
        promo: 50,
        productName: "Écouteurs  Bluetooth M10 pro",
        price: "38.00",
        color: "noir",
        badge: true,
        des: `🎧 <b>Caractéristiques Principales :</b><br /><br />
- 🖐️ <b>Contrôle Tactile Intuitif :</b> Abandonnez les boutons traditionnels. Un simple toucher permet de répondre/terminer/rejeter un appel, lire/mettre en pause votre musique, ajuster le volume ou activer l'assistant vocal.<br />
- 📶 <b>Bluetooth 5.3 :</b> Profitez d'une connectivité sans fil rapide et stable avec la dernière version Bluetooth 5.3.<br />
- 🔋 <b>Autonomie Optimale :</b> Batterie rechargeable intégrée offrant 80 minutes de temps de musique et jusqu'à 60 heures en veille.<br />
- 🎽 <b>Usage Polyvalent :</b> Idéal pour le sport, le travail ou vos activités quotidiennes, ces écouteurs s'adaptent à toutes vos situations.<br /><br />

<b>Spécifications Techniques :</b><br />
- Version Bluetooth : 5.3<br />
- Application : Sport, travail<br />
- Connectivité : Sans fil<br />
- Chipset : Airoha<br />
- Réponse en fréquence : 20-20000 Hz<br />
- Temps de musique : 80 minutes<br />
- Autonomie en veille : 60 heures<br /><br />

Avec ces écouteurs sans fil, profitez d'une expérience audio pratique et confortable, adaptée à tous vos besoins.<br /><br />

صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©`,
        category: "electronique"

    },
    {_id: "7",
        img:I9S1 ,
        images: [I9S3,I9S4,I9S1,I9S2,I9S5,],
        promo: 20,
        productName: "Écouteurs Bluetooth i9S TWS",
        price: "39.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Caractéristiques Principales :</b><br />
<br />
- 🎛️ <b>Contrôle Tactile Complet :</b> Abandonnez les boutons traditionnels. Touchez simplement pour contrôler vos écouteurs : répondre/terminer/rejeter un appel, lire/mettre en pause, ajuster le volume et activer l'assistant vocal.<br />
- 🔋 <b>Batterie Rechargeable Intégrée :</b> Profitez d'une transmission Bluetooth stable, d'une faible consommation d'énergie et d'une compatibilité renforcée. Livré avec un étui de chargement de 300 mAh pour un chargement mobile facile et pratique.<br />
- 🎶 <b>Son Surround Haute Définition :</b> Profitez d'un son surround de haute qualité avec une fonction de réduction de bruit pour une expérience musicale immersive.<br />
<br />
<b>✨ Avantages :</b><br />
<br />
- 🏃‍♂️ <b>Conception Ergonomique :</b> Offre un effet auditif stable et confortable, parfait pour le sport ou la marche. Idéal pour la course ou d'autres activités physiques.<br />
- 📱 <b>Compatibilité Universelle :</b> Fonctionne parfaitement avec la plupart des téléphones et tablettes, vous offrant une flexibilité maximale.<br />
- 🧳 <b>Facilité de Stockage et de Chargement :</b> L'étui de chargement compact de 300 mAh facilite le stockage et permet de recharger vos écouteurs en déplacement.<br />
<br />
<b>📦 Contenu du Produit :</b><br />
- Écouteurs Sans Fil<br />
- Étui de Chargement 300 mAh<br />
- Câble de Chargement<br />
- Manuel d'Utilisation<br />
<br />
<b>💡 Pourquoi Vous L'Adorerez :</b><br />
<br />
- <b>Confort et Stabilité :</b> Profitez d'un port confortable et stable grâce à une conception ergonomique adaptée à toutes vos activités.<br />
- <b>Contrôle Intuitif :</b> Gérez facilement vos appels, votre musique et votre assistant vocal avec un contrôle tactile complet.<br />
- <b>Qualité Audio Supérieure :</b> Plongez dans un son surround haute définition avec réduction de bruit pour une expérience audio inégalée.<br />
<br />
Optez pour nos écouteurs sans fil et profitez d'une liberté musicale totale avec un confort et une qualité audio exceptionnels ! 🎧✨<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,
        category: "electronique"

    },
    {_id: "1130",
        img: lenovo,
        images: [lenovo,lenovo3,lenovo2,lenovo4, ],
        promo: 30,
        productName: "Ecouteurs sans fil Lenovo LP40 PRO",
        price: "40.00",
        color: "noir",
        badge: true,
        des: `
<b>🎧 Découvrez les écouteurs sans fil parfaits :</b><br />
<br />
À la recherche des écouteurs sans fil parfaits avec des fonctionnalités de premier ordre ? Ne cherchez plus, car ces écouteurs sans fil ont tout ce qu'il vous faut ! Avec une <b>technologie Bluetooth 5.1 avancée</b> et des <b>commandes tactiles intuitives</b>, ils sont le compagnon audio ultime.<br />
<br />
<b>✨ Caractéristiques Impressionnantes :</b><br />
<br />
- 🔋 <b>Autonomie Prolongée :</b> Profitez d’une autonomie exceptionnelle grâce à un boîtier de charge de 300 mAh, offrant jusqu'à 4 heures d'utilisation continue et 150 heures en veille.<br />
- 🎨 <b>Design Élégant :</b> Combinez style et performance avec un design moderne et raffiné qui attire les regards.<br />
- 🛠️ <b>Puce Jerry AC6973 :</b> Bénéficiez d'une expérience sonore exceptionnelle grâce à cette technologie de pointe, conçue pour offrir une qualité audio irréprochable.<br />
<br />
<b>Pourquoi les choisir ?</b><br />
- Une connectivité stable et rapide.<br />
- Une ergonomie pensée pour un confort maximal.<br />
- Une combinaison parfaite entre technologie et style.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique",

    },
    {_id: "19931",
        img: lenovob1,
        images: [lenovob3,lenovob4, lenovob,lenovob1,lenovob2, ],
        promo: 30,
        productName: "Ecouteur sans fil Lenovo LP40 PRO",
        price: "40.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 À la recherche des écouteurs sans fil parfaits ?</b><br />
<br />
Ne cherchez plus ! Ces écouteurs sans fil sont conçus pour offrir des <b>performances de premier ordre</b> et un confort exceptionnel. Grâce à leur <b>technologie Bluetooth 5.1 avancée</b> et leurs <b>commandes tactiles intuitives</b>, ils sont le compagnon audio ultime.<br />
<br />
<b>✨ Fonctionnalités Impressionnantes :</b><br />
<br />
- 🔋 <b>Autonomie Prolongée :</b> Profitez d'une autonomie exceptionnelle grâce à un boîtier de charge de 300 mAh, offrant jusqu'à 4 heures d'utilisation continue et 150 heures en veille.<br />
- 🎨 <b>Design Élégant :</b> Un design moderne et stylé qui allie performance et esthétique.<br />
- 🛠️ <b>Puce Jerry AC6973 :</b> Dotée de la dernière technologie audio, cette puce offre une qualité sonore exceptionnelle à chaque utilisation.<br />
<br />
<b>Pourquoi les choisir ?</b><br />
<br />
- 🌐 Connectivité stable et rapide grâce au Bluetooth 5.1.<br />
- ✅ Commandes tactiles intuitives pour une expérience utilisateur fluide.<br />
- ✨ Une conception moderne et ergonomique pour un confort optimal.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },


    {_id: "4",
        img: inkaxT031,
        images: [inkaxT031,inkaxT033,inkaxT032,inkaxT034,inkaxT035,],
        promo: 20,
        productName: "Ecouteur Inkax T3",
        price: "46.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Caractéristiques Principales :</b><br />
<br />
- 🔗 <b>Bluetooth 5.0 :</b> Connexion rapide et stable à tous les appareils compatibles pour une transmission audio fluide et sans perte.<br />
- 🎮 <b>Mode Jeu :</b> Réduction de latence pour une immersion optimale dans vos jeux préférés.<br />
- 💧 <b>Design Ergonomique :</b> Confortable à porter, résistant à la transpiration et à l'eau, parfait pour les sessions prolongées ou l'entraînement.<br />
- 🎶 <b>Son Cristallin :</b> Profitez de basses profondes et d'aigus clairs pour une expérience sonore riche et immersive.<br />
- 🔇 <b>Réduction de Bruit CVC 6.0 :</b> Filtre efficacement les bruits de fond pour des appels et une écoute de haute qualité.<br />
- 🔋 <b>Autonomie Longue Durée :</b> Utilisez vos écouteurs toute la journée grâce à l’étui de chargement portable inclus.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },
    {_id: "3",
        img: inkax1,
        images: [  inkax1,inkax5,inkax3,inkax4,inkax2,],
        promo: 20,
        productName: "Ecouteur Inkax TWS-07",
        price: "49.00",
        color: "blanc",
        badge: true,
        des: `
<b>✨ Caractéristiques Principales :</b><br />
<br />
- 🔗 <b>Bluetooth 5.0 :</b> Connexion rapide et stable à tous les appareils compatibles pour une transmission audio fluide et sans perte.<br />
- 🎮 <b>Mode Jeu :</b> Réduction de latence pour une immersion optimale dans les jeux, offrant une expérience sans décalage.<br />
- 💧 <b>Design Ergonomique :</b> Conception confortable, résistante à la transpiration et à l'eau, parfaite pour des sessions prolongées ou pour l’entraînement.<br />
- 🎶 <b>Son Cristallin :</b> Profitez de basses profondes et d’aigus clairs, pour une expérience sonore riche et immersive.<br />
- 🔇 <b>Réduction de Bruit CVC 6.0 :</b> Filtre efficacement les bruits de fond pour des appels d'une clarté exceptionnelle.<br />
- 🔋 <b>Autonomie Longue Durée :</b> Profitez de longues heures d'utilisation grâce à l’étui de chargement portable inclus.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },
    {_id: "5",
        img:bt1 ,
        images: [bt1,bt2,bt3,bt4,bt5,],
        promo: 20,
        productName: "Écouteurs Bluetooth BT-35 - Game Mode",
        price: "59.00",
        color: "noir,rose",
        badge: true,
        des: `
<b>✨ Les Écouteurs Bluetooth BT-35 :</b><br />
<br />
Les écouteurs BT-35 offrent une expérience d'écoute exceptionnelle, conçus pour allier qualité audio et praticité.<br />
Dotés de la technologie Bluetooth, ils garantissent une connexion rapide et stable à votre appareil, idéaux pour vos déplacements quotidiens ou voyages.<br />
<br />

<b>🌟 Caractéristiques Principales :</b><br />
- 🎧 <b>Son de Qualité Supérieure :</b> Haut-parleurs de haute précision pour un son stéréo HiFi clair et immersif.<br />
- 🔇 <b>Suppression du Bruit ENC :</b> Réduction des bruits de fond pour des appels et une musique sans distraction.<br />
- 🔋 <b>Autonomie Exceptionnelle :</b> Jusqu'à 5 heures d'écoute continue, avec 20 heures supplémentaires grâce au boîtier de recharge compact inclus.<br />
- ⚡ <b>Charge Rapide :</b> Rechargez vos écouteurs en un rien de temps pour une utilisation prolongée.<br />
- 🕹️ <b>Compatibilité de Jeu :</b> Mode jeu pour une latence réduite et une immersion totale.<br />
- 🌊 <b>Résistants à l’Eau et à la Sueur :</b> Parfaits pour vos entraînements et activités sportives.<br />
- 🖱️ <b>Contrôle Tactile :</b> Navigation facile avec un simple toucher pour appairer, lire, mettre en pause ou répondre à un appel.<br />
- 🔢 <b>Affichage à LED :</b> Boîtier de charge avec affichage numérique pour surveiller le niveau de batterie.<br />
<br />

<b>🚀 Pourquoi Choisir les BT-35 ?</b><br />
Avec leur design ergonomique, les écouteurs BT-35 assurent un confort optimal même après de longues heures d'utilisation.<br />
Profitez d’une expérience audio complète et sans compromis, où que vous soyez !<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },

    {_id: "2",
        img: A1,
        images: [  A3,A2,A4,A5,A1,A6,],
        promo: 20,
        productName: "Ecouteur Inkax A9 Pro avec Afficheur",
        price: "69.00",
        color: "blanc",
        badge: true,
        des: `
<b>🎧 Ecouteur Bluetooth Inkax ENC avec Écran Tactile Blanc ✨</b><br />
<br />

<b>🌟 Contrôle de l'Écran Tactile Futuriste</b><br />
Naviguez sans effort grâce à l'écran intelligent en couleur intégré et à l'écran tactile. Ajustez vos paramètres, modifiez le volume ou répondez à vos appels avec quelques tapotements. Une interface conviviale pour une expérience audio intuitive et moderne.<br />
<br />

<b>🔇 Annulation de Bruit Supérieure</b><br />
Profitez d'une immersion totale grâce à la technologie avancée ANC (Annulation Active du Bruit). L'A9 Pro bloque efficacement les bruits de fond pour une écoute claire et sans distraction, idéale pour la musique, les appels ou le travail.<br />
<br />

<b>🔋 Durée de Vie de la Batterie Impressionnante</b><br />
Restez connecté toute la journée : jusqu'à 8 heures d'utilisation continue sur une seule charge, et jusqu'à 24 heures avec le boîtier de charge inclus. Une autonomie fiable pour répondre à tous vos besoins.<br />
<br />

<b>👂 Ajustement Confortable et Sécurisé</b><br />
Avec sa conception ergonomique et ses options d'écouteurs avancées, l'A9 Pro offre un ajustement parfait et sécurisé pour vos oreilles. Un confort optimal, même après de longues heures d'utilisation.<br />
<br />

<b>✨ Pourquoi Choisir l'A9 Pro ?</b><br />
L'A9 Pro allie technologie avancée, design élégant et fonctionnalités performantes pour une expérience audio exceptionnelle. Que vous soyez en déplacement, au travail ou à la maison, il est conçu pour répondre à vos exigences les plus élevées.<br />
<br />
صنع من أجل تجارة إلكترونية أفضل ❤️ ShopyCloud | 2024 ©
`,

        category: "electronique"

    },




    {
    _id: "1101",
    img: robot2,
    images: [robot2, robot1,  robot3 , robot4],
    productName: "Robot de Nettoyage Intelligent",
    price: "59.00",
    color: "noir , blanc",
    badge: true,
    promo:30,
    des: "Robot Aspirateur de Nettoyage Intelligent – La Solution Moderne pour \n" +
        "une Maison Propre \n" +
        "Le Robot Aspirateur de Nettoyage Intelligent est conçu pour vous offrir une \n" +
        "maison impeccablement propre avec un minimum d'effort. Doté \n" +
        "d’une technologie avancée et d’un design pratique, cet appareil rechargeable \n" +
        "par USB assure un nettoyage en profondeur, quel que soit le type de sol, tout \n" +
        "en étant silencieux et facile à utiliser. AVANTAGES :\n" +
        " Nettoyage Automatique : Élimine poussière, poils et peluches de manière \n" +
        "autonome, sans aucune intervention manuelle. \n" +
        "Ultra Silencieux : Fonctionne avec une technologie de réduction du \n" +
        "bruit pour ne pas perturber votre quotidien. \n" +
        "Recharge USB Pratique : Se recharge facilement via n'importe quel \n" +
        "port USB, vous offrant flexibilité et commodité. \n" +
        "Gain de Temps : Libérez du temps pour vos activités préférées, pendant \n" +
        "que le robot se charge du nettoyage. \n" +
        "CARACTÉRISTIQUES :\n" +
        " Mode de Frottement Manuel : Simule le frottement des mains pour un \n" +
        "nettoyage plus minutieux. \n" +
        "Nettoyage en Profondeur : Couvre une large surface et capture même les \n" +
        "plus petites particules. \n" +
        "Design Universel : Le robot change de direction automatiquement en \n" +
        "rencontrant des obstacles, garantissant une couverture complète de votre \n" +
        "espace. \n" +
        "Sécurité Anti-Chute : Équipé d’un système anti-chute pour éviter les \n" +
        "accidents. \n" +
        "SPÉCIFICATIONS :\n" +
        " Nom du Produit : Robot Aspirateur de Nettoyage Intelligent\n" +
        " Tension de Fonctionnement : 7 V / 1500 mAh\n" +
        " Temps de Charge : Environ 3 heures (chargement USB)\n" +
        " Niveau Sonore : Environ 55 dB\n" +
        " Autonomie : Jusqu’à 90 minutes d’utilisation continue \n" +
        "Batterie : Batterie intégrée\n" +
        " Poids : Léger et facile à déplacer \n" +
        "Ne laissez plus le ménage envahir votre emploi du temps. Commandez dès \n" +
        "aujourd'hui le Robot Aspirateur de Nettoyage Intelligent et profitez d'un \n" +
        "intérieur propre sans effort !",

    category: "facilite",
  },
  {
    _id: "1102",
    img: etagiereLumineuse,
    images: [etagiereLumineuse, etagiereLumineuse2, etagiereLumineuse3 , etagiereLumineuse4, etagiereLumineuse5],
    productName: "Étagère Lumineuse",
    price: "49.00",
    promo: 20,
    color: "bois",
    badge: true,
    des: "Étagère Murale en Corde de Chanvre avec Guirlandes Lumineuses – \n" +
        "Créez une Atmosphère Chaleureuse et Romantique ! \n" +
        "Description :\n" +
        " Ajoutez une touche de charme naturel à votre intérieur avec notre étagère \n" +
        "murale en corde de chanvre, décorée de guirlandes lumineuses. Cette pièce \n" +
        "unique crée une ambiance chaleureuse et accueillante, parfaite pour toute \n" +
        "maison.\n" +
        " Caractéristiques Principales :\n" +
        " ●\n" +
        " ●\n" +
        " ●\n" +
        " ●\n" +
        " ●\n" +
        " Conception Élégante : L'étagère est enveloppée de bois naturel et ornée \n" +
        "de guirlandes lumineuses sur une ficelle suspendue, ajoutant une ambiance \n" +
        "romantique à votre espace. \n" +
        "Détails Dimensionnels : Avec des dimensions d'environ 40 x 13 x 1,3 cm, \n" +
        "cette étagère est suffisamment compacte pour s'intégrer harmonieusement \n" +
        "sans prendre trop d'espace d'affichage. \n" +
        "Installation Facile : Installez-la en un rien de temps : passez la corde à \n" +
        "travers les trous de l'étagère, enroulez la guirlande lumineuse LED et la \n" +
        "guirlande de lierre artificiel autour, puis accrochez-la où vous le souhaitez. \n" +
        "Simple et pratique ! \n" +
        "Décoration de Chambre Chic : Non seulement elle expose des plantes, \n" +
        "des souvenirs d'art et des livres, mais elle transforme également votre \n" +
        "maison en un jardin botanique. Avec une verdure élégante et des lumières \n" +
        "LED, cette étagère est le mélange parfait de style rustique, bohème et \n" +
        "sophistiqué. \n" +
        "Polyvalence : Idéale pour contenir des micro-paysages de plantes \n" +
        "d'intérieur, des souvenirs, et des décorations artistiques. C'est la \n" +
        "décoration parfaite pour votre salon, chambre, salle de bain, ferme et \n" +
        "cuisine. Un cadeau idéal pour vos proches ! \n" +
        "Embellissez votre espace avec cette étagère murale en corde de \n" +
        "chanvre, alliant fonctionnalité et esthétique. Parfaite pour ceux qui cherchent à \n" +
        "créer une atmosphère unique et accueillante chez vous",
    category: "decoration",
  },
    {
        _id: "1103",
        img: raclette2,
        images: [raclette2, raclette1,  raclette3 , raclette4],
        productName: "Mini Mop Rotatif à Essorage",
        price: "29.00",
        color: "vert , blanc",
        badge: true,
        promo:20,
        des: "Découvrez le meilleur de la simplicité pour le nettoyage domestique avec notre \n" +
            "Mini Mop Rotatif à Essorage Sans Lavage à la Main pour Sols. Ce balai innovant \n" +
            "est conçu pour simplifier votre routine de nettoyage et offre de superbes \n" +
            "performances sur plusieurs types de surfaces.\n" +
            " Caractéristiques principales :\n" +
            " Nettoyage sans les mains : Dites adieu au lavage manuel des tampons de balai. \n" +
            "Notre design sans lavage à la main permet un nettoyage facile et hygiénique, \n" +
            "sans vous salir les mains.\n" +
            " Nettoyage multi-surfaces : Ce mini balai rotatif à essorage est une solution \n" +
            "tout-en-un pour nettoyer de nombreuses surfaces. Nettoyez les sols et bien \n" +
            "plus encore avec un outil polyvalent.\n" +
            " Action d'essorage efficace : La fonction rotative garantit un nettoyage complet \n" +
            "et efficace. Les taches et la saleté tenaces ne résistent pas aux puissants \n" +
            "résultats de ce balai.\n" +
            " Mécanisme d'essorage : Obtenez le niveau d'humidité idéal pour vos tâches de \n" +
            "nettoyage grâce au mécanisme d'essorage pratique. Plus d’excès d'eau, juste \n" +
            "des sols impeccables.\n" +
            " Compact et portable : Conçu pour un rangement et une portabilité faciles, ce \n" +
            "mini balai est parfait pour les petits espaces. Gardez vos outils de nettoyage à \n" +
            "portée de main sans encombrer votre maison.\n" +
            " Améliorez votre routine de nettoyage avec le Mini Mop Rotatif à Essorage Sans \n" +
            "Lavage à la Main pour Sols. Profitez d’un nettoyage sans les mains, multi\n" +
            "surfaces, d'une action d’essorage efficace et d'un design compact. Facilitez \n" +
            "vos tâches de nettoyage !",
        category: "facilite",
    },

    {
        _id: "1104",
        img: porteSac3,
        images: [porteSac3, porteSac, porteSac2,  porteSac4],
        productName: "Porte-Sac à Main Élégant",
        price: "49.00",
        promo: 30,
        color: "blanc,gris,noir",
        badge: true,
        des: "Porte-Sac à Main Élégant – Organisation, gain d’espace et Protection pour \n" +
            "vos Sacs Précieux\n" +
            " Découvrez en exclusivité notre tout nouveau porte-sac à main, un accessoire \n" +
            "haut de gamme conçu pour optimiser l'organisation de vos sacs tout en \n" +
            "économisant de l'espace. Spécialement conçu pour allier praticité et élégance, \n" +
            "ce porte-sac est parfait pour celles et ceux qui souhaitent un rangement \n" +
            "ordonné et stylé.\n" +
            " Les Points Forts de Notre Porte-Sac\n" +

            " Gain d’Espace Optimal : Libérez de la place dans vos placards et rangez \n" +
            "vos sacs de manière intelligente et organisée. Ce porte-sac est idéal pour \n" +
            "maximiser l'espace, même dans les petits appartements !\n" +
            " Protection et Style : Préservez vos sacs de l’usure et de la poussière tout \n" +
            "en les exposant avec élégance. Ce porte-sac protège vos accessoires de \n" +
            "valeur et vous permet d’y accéder facilement, sans encombrement.\n" +
            " Organisation Professionnelle : Transformez votre espace avec un \n" +
            "accessoire qui allie luxe et fonctionnalité. Grâce à ce porte-sac, vos sacs \n" +
            "ne seront plus jamais empilés ou rangés au hasard.\n" +
            " Profitez de Notre Promotion de Lancement\n" +
            " Pour célébrer cette nouveauté, bénéficiez d’une offre spéciale de lancement ! \n" +
            "Achetez-en deux pour une remise exclusive et optimisez votre espace de \n" +
            "rangement tout en valorisant votre collection de sacs. Attention, cette offre \n" +
            "limitée n’est disponible que pour une durée restreinte !\n" +
            " Ce porte-sac incarne l’innovation en matière de rangement et de design, parfait \n" +
            "pour organiser et mettre en valeur vos sacs tout en gagnant de l'espace. Ne \n" +
            "manquez pas l’occasion d’obtenir ce produit exclusif et de profiter de notre \n" +
            "promotion de lancement  Contenu de l’emballage : 1 support de rangement (peut contenir 6 sacs).\n" +
            "Couleur : Transparent, noir.\n" +
            "Dimensions approximatives : 35 x 30 x 100cm.\n" +
            "Matériaux : oxford 600 D impermeable et PVC transparent.",
        category: "facilite",
    },
    {
        _id: "1105",
        img: purificateur4,
        images: [ purificateur4, purificateur, purificateur2 , purificateur5, purificateur6, purificateur7],
        productName: "Purificateur d'eau de Robinet",
        promo: 40,
        price: "59.00",
        color: "Blanc",
        badge: true,
        des : "Purificateur de Robinet : Protégez Votre Santé avec une Eau Pure et Sûre 🚰\n" +
            "L'eau est essentielle à notre quotidien, mais les impuretés présentes dans l'eau du robinet peuvent avoir un impact négatif sur notre santé. Pour assurer une eau propre et saine, optez pour notre Purificateur de Robinet. Cet appareil pratique et efficace vous garantit une eau filtrée, prête à être utilisée pour boire, cuisiner, ou même pour vos soins de la peau.\n" +
            "\n" +
            "Caractéristiques Principales :\n" +
            "Petit et Pratique : Compact et facile à installer, ce purificateur ne nécessite aucune électricité, ce qui le rend idéal pour une utilisation domestique.\n" +
            "Filtration à 7 Couches : Offre une filtration précise et efficace, éliminant les sédiments, la rouille, les vers rouges et autres impuretés de l'eau du robinet.\n" +
            "Élément Filtrant en Céramique : Assure une filtration de haute précision (0,1 micron) pour une eau purifiée en profondeur.\n" +
            "Longue Durée de Vie : L'élément filtrant peut être nettoyé pour prolonger sa durée de vie jusqu'à 3 à 6 mois, en fonction de la qualité de l'eau.\n" +
            "Installation Facile : Fourni avec des adaptateurs et connecteurs pour s'adapter à différents types de robinets, il s'installe en un rien de temps.\n" +
            "Spécifications Techniques :\n" +
            "Matériau de l'élément filtrant : Céramique\n" +
            "Couleur : Blanc\n" +
            "Température de l'eau appropriée : 0 ~ 38°C\n" +
            "Débit : 1 L/Min\n" +
            "Durée de vie de l'élément filtrant : 3 à 6 mois\n" +
            "Taille du purificateur : 15011565 mm\n" +
            "Poids du colis : 400 g\n" +
            "Contenu du Paquet :\n" +
            "1 * Purificateur d'eau (avec élément filtrant)\n" +
            "4 * Convertisseurs de robinet\n" +
            "1 * Connecteur multifonctionnel\n" +
            "2 * Rondelles\n" +
            "1 * Manuel d'utilisation\n" +
            "💧 Investissez dans la santé de votre famille avec notre purificateur de robinet. Profitez d'une eau pure, propre et exempte d'impuretés à tout moment.",
        category: "maison",
    },
    {
        _id: "1106",
        img: etagiereDouble,
        images: [etagiereDouble, etagiereDouble2, etagiereDouble3, etagiereDouble4, etagiereDouble5, etagiereDouble6],
        promo: 20,
        productName: "Étagère Double",
        price: "59.00",
        color: "bois",
        badge: true,

        des :"  Étagère DOUBLE murale suspendue en bois rustique avec corde\n" +
            "Cette étagère double murale suspendue en bois rustique, soutenue par des\n" +
            "cordes, offre à la fois style et fonctionnalité pour différents espaces intérieurs.\n" +
            "Idéale pour le rangement de plantes, livres et objets décoratifs, elle s'intègre \n" +
            "parfaitement dans des ambiances variées telles que maritime, rétro ou\n" +
            "campagne.\n" +
            "Points clés :\n" +

            " Design multifonctionnel : Parfaite pour exposer des plantes, des livres ou\n" +
            "des objets décoratifs.\n" +
            " Matériaux de qualité : Fabriquée en bois de paulownia léger et résistant,\n" +
            "avec des cordes en jute.\n" +
            " Installation facile : Déjà assemblée, elle se fixe facilement au mur ou au\n" +
            "plafond grâce à des crochets inoxydables.\n" +
            " Économie d'espace : Offre un espace de rangement vertical \n" +
            "supplémentaire tout en étant esthétique.\n" +
            " Idée cadeau : Un cadeau créatif et pratique pour diverses occasions.\n" +
            " Ce produit combine l'utilité à l'esthétique, tout en étant simple à installer et\n" +
            "adaptable à différents types d'intérieurs.",
        category: "decoration",
    },
    {
        _id: "1107",
        img: turboDouche,
        images: [turboDouche, turboDouche2, turboDouche3, turboDouche4, turboDouche5],
        promo: 25,
        productName: "Douchette Turbo avec 3 Modes",
        price: "39.00",
        color: "chrome",
        badge: true,
        des : "1. Fabriqué en ABS de haute qualité, sûr et respectueux de l'environnement, résistant à l'usure et aux chutes.\n" +
            "\n" +
            "2. La sélection multi-modes répond aux besoins de chacun pour la douche. Avec la fonction de tête de massage, vous pouvez masser la tête pour vous détendre pendant la douche.\n" +
            "\n" +
            "3. Utilisez différents modes pour rincer la tête, et la douche avec filtre permet de garder l'eau propre.\n" +
            "\n" +
            "4. La surface lisse ne raye pas les mains ; qu'il s'agisse d'un adulte ou d'un enfant, il peut être utilisé en toute confiance.\n" +
            "\n" +
            "Longueur du tuyau de douche : 1,5 m/59,06 pouces\n" +
            "Fonctions : 3 modes\n" +
            "Poids : 250 g\n" +
            "Dimensions du produit : 25 x 7 cm/9,84 x 2,75 pouces\n" +
            "\n" +
            "Matériau de la tête de douche : plastique ABS + filtre en coton PP\n" +
            "Matériau du support de tête de douche : plastique ABS\n" +
            "Matériau du tuyau de douche : acier inoxydable, Écrou : acier inoxydable, Noyau : laiton",
        category: "maison",
    },
    {
        _id: "1108",
        img: manteau3,
        images: [manteau3 , manteau1, manteau2, manteau4, manteau5, manteau6,manteau7],
        promo: 20,
        productName: "Manteau Imperméable",
        price: "29.00",
        color: "jaune,vert",
        badge: true,
        des : "Manteau Imperméable, Protection Pluie\n" +
            " Ce poncho écologique et innovant offre un équilibre parfait entre performance \n" +
            "et durabilité. Fabriqué avec un matériau imperméable, respirant et résistant à \n" +
            "l'abrasion, il garantit un confort maximal, même sous des pluies torrentielles.\n" +
            " Principaux atouts :\n" +
            " Protection complète : Sa grande taille couvre également le sac à dos, \n" +
            "protégeant de la pluie et du vent.\n" +
            " Confort supérieur : Le matériau évacue l'humidité, offrant une sensation \n" +
            "de fraîcheur et de bien-être.\n" +
            " Polyvalence : Idéal pour diverses activités extérieures, comme la \n" +
            "randonnée, le camping, les festivals et les déplacements quotidiens.\n" +
            " Facilité d'utilisation : Il se met facilement sur les vêtements, assurant une \n" +
            "protection rapide.\n" +
            " Ce poncho allie design moderne et technologie de pointe, idéal pour ceux qui \n" +
            "recherchent une protection fiable et respectueuse de l'environnement.",
        category: "facilite",
    },
    {
        _id: "1109",
        img: guirlandes8,
        images: [guirlandes8 , guirlandes, guirlandes2, guirlandes3, guirlandes4, guirlandes5, guirlandes6, guirlandes7, guirlandes9],
        promo: 20,
        productName: "Guirlande lumineuse - 10M",
        price: "63.00",
        color: "8 modes",
        badge: true,
        des : "Guirlande lumineuse de feuilles vertes de lierre artificiel - 10M\n" +
            "\n" +
            "\n" +
            "\n" +
            "Cette guirlande lumineuse LED avec des feuilles de lierre artificielles réalistes est idéale pour décorer différents espaces, tels que les chambres, jardins, balcons, mariages, et événements festifs. Elle offre une lumière agréable et crée des ambiances variées, tant de jour que de nuit.\n" +
            "Points clés :\n" +
            "* Polyvalence décorative : Utilisable pour la décoration intérieure et extérieure, notamment pour les mariages, les fêtes, les jardins ou comme lampe murale.\n" +
            "* Matériau durable : Les feuilles en soie et les tiges en plastique résistent aux intempéries et ne se décolorent pas facilement.\n" +
            "* 8 modes de lumière LED : Offrant une grande variété d’effets lumineux pour s'adapter à toutes les occasions.\n" +
            "* Facilité d'installation : Facile à suspendre et à démonter.\n" +
            "* Dimensions et accessoires : \n" +
            "Chaque lierre mesure environ 2,5 m de long, un sachet de 4 guirlandes de lierre artificielle, soit un total de 84 pieds, 72 feuilles, diamètre d'environ 1,37 pouces. Les feuilles sont en soie et les tiges en plastique\n",
        category: "decoration",
    },

    {
        _id: "1110",
        img: destributeurD1,
        images: [destributeurD1, destributeurD2, destributeurD3, destributeurD4],
        promo: 30,
        productName: " Distributeur de Dentifrice Automatique",
        price: "45.00",
        color: "noir",
        badge: true,
        des : "sans dégâts, adapté aux enfants: Si vous avez des enfants qui\n" +
            "commencent à se brosser les dents seuls, ce distributeur automatique de\n" +
            "dentifrice vous facilitera la vie ! Plus besoin de nettoyer du dentifrice sur les\n" +
            "murs et le plafond. Ce distributeur aide à minimiser les gâchis et à économiser\n" +
            "du dentifrice.\n" +
            "Économique & Facile à Utiliser: Équipé d'un porte \n" +
            "brosse mural pratique cet appareil est simple d’utilisation pour les petits comme les grands. Il permet\n" +
            "de contrôler facilement la quantité de dentifrice pour éviter le gaspillage.\n" +
            "2 Gobelets de Rinçage et 4 Emplacements pour Brosses: Le design\n" +
            "astucieux des gobelets à rinçage renversés assure qu'ils s'égouttent\n" +
            "correctement. Le support peut contenir jusqu'à 4 brosses à dents, y compris\n" +
            "les brosses électriques , pour adultes et enfants.\n" +
            "Installation Facile: Pas besoin de percer ou de faire des trous dans vos\n" +
            "murs ! Nettoyez simplement la surface, collez la bande adhésive, et attendez\n" +
            "24 heures avant de l'utiliser. Convient aux surfaces lisses comme le carrelage,\n" +
            "le marbre, le verre et le métal.\n" +
            "Matériau de Qualité Alimentaire: Fabriqué en ABS sans odeur, ce\n" +
            "distributeur garantit une utilisation en toute sécurité, même pour les gobelets\n" +
            "de rinçage qui résistent à de hautes températures.\n" +
            "Entretien Facile: Ce distributeur de dentifrice est facile à démonter et à\n" +
            "nettoyer. Il s’adapte aux tubes de dentifrice avec un diamètre d'ouverture de\n" +
            "0,55 pouces ou moins, assurant une propreté et une hygiène parfaites.\n" +
            "Apportez de l’ordre à votre routine quotidienne avec ce distributeur de\n" +
            "dentifrice pratique et élégant !",
        category: "facilite",
    },
    {
        _id: "1111",
        img: friteuse,
        images: [friteuse, friteuse2, friteuse3, friteuse4, friteuse5,friteuse6, friteuse7, friteuse8],
        promo: 25,
        productName: "Friteuse à Air Ultrean",
        price: "149.00",
        color: "noir",
        badge: true,
        des : "Friteuse à Air Ultrean, la solution idéale pour des repas sains et savoureux ! \n" +
            "Découvrez la Friteuse à Air Ultrean, l'appareil de cuisine qui révolutionne la \n" +
            "façon dont vous préparez vos repas. Profitez de plats croustillants à l’extérieur \n" +
            "et tendres à l’intérieur avec jusqu’à 85 % de matières grasses en \n" +
            "moins grâce à la technologie avancée de circulation d’air rapide à 360°!\n" +
            "Caractéristiques :\n" +
            "Cuisson Saine :\n" +
            "Fritez de manière plus saine avec moins d’huile tout en savourant vos \n" +
            "aliments préférés.\n" +
            "Multifonctionnelle et Facile à Utiliser :\n" +
            "Équipée d’un écran tactile numérique, cette friteuse propose 10 \n" +
            "préréglages pour une cuisson parfaite :\n" +
            "Toast, Chips, Chips congelées, Cuisses de poulet, Crevettes, Steak, \n" +
            "Poisson, Pizza, Gâteau.\n" +
            "Personnalisez facilement les réglages pour cuire vos aliments exactement \n" +
            "comme vous le souhaitez.\n" +
            "Facile à Nettoyer et Sécurisée :\n" +
            "Panier et marmite à frites non collants et faciles à nettoyer, avec pièces \n" +
            "amovibles compatibles avec le lave-vaisselle.\n" +
            "Équipée d’un arrêt automatique pour éviter la surchauffe ; sans PFOA ni \n" +
            "BPA pour une sécurité accrue.\n" +
            "Design Contemporain et Élégant :\n" +
            "Finition mate sur le dessus réduisant les traces de doigts et les rayures.\n" +
            "Grande capacité de 6L, idéale pour servir 3 à 5 personnes.\n" +
            "Livrée avec un fabuleux livre de recettes bonus, vous offrant des recettes \n" +
            "délicieuses et faciles pour vos repas quotidiens.\n" +
            "Spécifications :\n" +

            " Marque : TESKA\n" +
            " Puissance : 2400W\n" +
            " Contenance : 6L ",
        category: "maison",
    },
    {_id: "1112",
        img: humidificateur,
        images: [humidificateur, humidificateur2, humidificateur3, humidificateur4, humidificateur5, humidificateur6, humidificateur7],
        promo: 50,
        productName: "humidificateur Éblouissant",
        price: "39.00",
        color: "noir , blanc",
        badge: true,
        des : "Cet humidificateur compact et éblouissant est bien plus qu'un simple appareil \n" +
            "de bureau; c'est une véritable œuvre d'art. Avec un réservoir d'eau de 400 ml, il \n" +
            "offre jusqu'à 6 heures d'humidification continue, répondant à tous vos besoins \n" +
            "en matière d'humidification. \n" +
            " Caractéristiques : \n" +
            " Capacité de 400 ml \n" +
            " : Le réservoir d'eau généreux permet jusqu'à 6 \n" +
            "heures d'utilisation continue, parfait pour une journée entière de travail ou \n" +"pour la nuit. \n" +
            " Mode Lumière Éblouissante \n" +
            " : Ce mode transforme l'humidificateur en \n" +
            "une pièce artistique. La lumière douce à sept couleurs crée une ambiance \n" +
            "apaisante et relaxante, tandis que la lumière blanche est idéale pour être \n" +
            "utilisée comme petite veilleuse. \n" +
            " Utilisation Facile \n" +
            " : Le produit ne peut être utilisé qu'après avoir ajouté \n" +
            "de l'eau. Assurez-vous de débrancher l'appareil avant de le remplir. \n" +
            " Transformez votre espace de travail ou de repos en un environnement plus \n" +
            "agréable et relaxant avec cet humidificateur élégant et fonctionnel",
        category: "facilite",

    },
    {_id: "1113",
        img: brosseVitres,
        images: [brosseVitres, brosseVitres2, brosseVitres3, brosseVitres4, brosseVitres5],
        promo: 50,
        productName: "Nettoyeur  de vitres magnétique",
        price: "29.00",
        color: "jaune",
        badge: true,
        des :": avec une conception magnétique double face \n" +
            "puissante, ce nettoyeur de vitres magnétique double face vous permet de \n" +
            "nettoyer votre fenêtre sans avoir à risquer de vous tenir debout à la fenêtre. \n" +
            "L'éponge de stockage d'eau intégrée peut être utilisée pendant une longue \n" +
            "période, évitant le réapprovisionnement répété en eau et facile à utiliser. Corde \n" +
            "anti-chute plus sûre : l' outil de nettoyage de vitres magnétique est livré avec 1 \n" +
            "longue corde anti-chute sûre, lorsque vous utilisez cet outil de nettoyage de \n" +
            "vitres, la corde sera attachée à votre main, ne tombant jamais. Vous permet \n" +
            "d'essuyer le verre de manière plus sûre, vous pouvez nettoyer l'extérieur en \n" +
            "vous tenant debout dans la pièce ; Très important : le verre de différentes \n" +
            "épaisseurs doit être utilisé sur différents essuie-glaces magnétiques, sinon il ne \n" +
            "peut pas bouger ou tomber du verre. Vous pouvez apprendre la méthode de \n" +
            "mesure de l'épaisseur du verre. Appliquez du détergent : assurez-vous \n" +
            "d'appliquer du détergent sur le coton de nettoyage, sinon le grattoir est difficile \n" +
            "à déplacer. Déplacez lentement le nettoyeur de vitres magnétique pendant \n" +
            "l'utilisation, plus la vitesse de déplacement est lente, meilleur est l'effet de \n" +
            "nettoyage. Prenez soin de votre main : l'article est très magnétique, veuillez \n" +
            "prendre soin de vos mains pendant le processus de demande. Lorsque vous \n" +
            "ouvrez l'essuie-glace, tenez la poignée à deux mains et faites pivoter l'essuie\n" +
            "glace pour l'écarter. Lors de sa fermeture, à l'exception de 3 à 8 mm, les autres \n" +
            "essuie-glaces ont des cloisons. Utilisez des cloisons pour séparer les essuie\n" +
            "glaces, veillez à ne pas vous pincer les doigts ici.\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " Matériau: plastique\n" +
            " Utilisez la scène: maison\n" +
            " Taille: 16*13.5cm\n" +
            " Épaisseur: 3 -8mm\n" +
            " Forme: triangle\n" +
            " Le paquet comprend:\n" +
            " 1 * Nettoyeur de vitres à double couches\n",
        category: "facilite",

    },
    {_id: "1114",
        img: secheLinge,
        images: [secheLinge, secheLinge2, secheLinge3, secheLinge4, secheLinge5],
        promo: 20,
        productName: "Sèche-linge Mural Rabattable",
        price: "59.00",
        color: "gris",
        badge: true,
        des :" Conception Murale\n" +

            " Gain de Place : Le sèche-linge mural est conçu pour être monté sur le mur, \n" +
            "se repliant facilement lorsqu'il n'est pas utilisé. Parfait pour économiser de \n" +
            "l'espace dans les petites pièces comme les salles de bains ou les \n" +
            "buanderies.\n" +
            " Matériau de Qualité\n" +

            " Acier Inoxydable : Fabriqué en acier galvanisé avec une peinture époxy \n" +
            "grise, ce sèche-linge est durable, résistant à la corrosion et esthétique, \n" +
            "idéal pour les environnements humides.\n" +
            " Pliage Pratique\n" +

            " Optimisation de l'Espace : Grâce à sa capacité de pliage, ce sèche-linge \n" +
            "se range facilement contre le mur lorsqu'il n'est pas utilisé, permettant une \n" +
            "optimisation maximale de l'espace.\n" +
            " Spécifications\n" +

            " Matériau : Acier galvanisé avec peinture époxy grise\n" +
            " Fonctionnalité : Rabattable pour un rangement facile\n" +
            " Composition : 2 bras avec 3 trous chacun\n" +
            " Inclus : Corde et matériel de fixation fournis\n" +
            " Avantages\n" +

            " Espace Optimisé : Parfait pour les petits espaces, il se replie contre le mur \n" +
            "pour ne pas encombrer.\n" +
            " Durabilité : L'acier galvanisé avec peinture époxy est résistant à la \n" +
            "corrosion, assurant une longue durée de vie.\n" +
            " Esthétique : Son design moderne et sobre s'intègre facilement dans \n" +
            "n'importe quel décor.\n" +
            " Facilité d'Installation\n" +

            " Matériel de Fixation Inclus : Tout le nécessaire pour fixer le sèche-linge \n" +
            "au mur est fourni, garantissant une installation simple et rapide.\n" +
            " Idéal Pour\n" +

            " Salles de Bains\n" +
            " Buanderies\n" +
            " Espaces Restreints\n" +
            " Pourquoi Choisir Notre Sèche-linge Mural ?\n" +
            " Praticité : Solution de séchage efficace qui se replie lorsqu'elle n'est pas \n" +
            "utilisée.\n" +
            " Qualité Supérieure : Fabriqué en acier inoxydable, résistant et esthétique.\n" +
            " Espace Optimisé : Parfait pour les petits espaces, offrant un rangement \n" +
            "facile et peu encombrant",
        category: "maison",
    },
    {_id: "1115",
        img: nebuliseur,
        images: [nebuliseur,nebuliseur2,nebuliseur3,nebuliseur4,nebuliseur5],
        promo: 50,
        productName: "Nébuliseur Mesh ",
        price: "39.00",
        color: "blanc",
        badge: true,
        des :"Le Nébuliseur Mesh MY126 est l'allié parfait pour soulager rapidement et \n" +
            "efficacement les affections respiratoires. Grâce à son design portable, compact \n" +
            "et silencieux, il est idéal pour toute la famille !\n" +
            " Soulagement rapide des affections respiratoires :\n" +
            " Le nébuliseur MY126 ouvre vos voies respiratoires et aide à réduire les \n" +
            "symptômes liés à l'asthme, la toux, la MPOC, et bien plus encore !\n" +
            " Convient à tous les âges :\n" +
            " Que ce soit pour les adultes, enfants ou bébés, ce nébuliseur est livré avec des \n" +
            "masques et embouts buccaux pour chaque membre de la famille.\n" +
            " Rechargeable via USB :\n" +
            " Fini les piles ! La batterie intégrée se recharge facilement avec le câble USB \n" +
            "fourni.\n" +
            " Facile à emporter partout :\n" +
            " Son design compact vous permet de glisser le nébuliseur dans votre sac pour \n" +
            "l’avoir à portée de main, que ce soit à la maison ou en déplacement.\n" +
            " Silencieux et discret :\n" +
            " Pas de bruit gênant ! Le nébuliseur MY126 est extrêmement silencieux, vous \n" +
            "offrant un traitement confortable.\n" +
            " 2 modes adaptés à vous et à vos enfants :\n" +
            " Profitez de deux modes de traitement pour un confort optimal pour vous et vos \n" +
            "enfants.\n" +
            " Grande capacité : 20 ml :\n" +
            " Son réservoir de 20 ml vous permet d'ajouter suffisamment de médicaments \n" +
            "pour un traitement efficace.\n" +
            " Fonction autonettoyante :\n" +
            " Nettoyage simplifié avec sa fonction d'autonettoyage ! Remplissez-le d’eau et \n" +
            "laissez-le fonctionner pendant 5 minutes après usage.\n" +
            " Le Nébuliseur Mesh MY126 est le choix idéal pour un soulagement efficace des \n" +
            "problèmes respiratoires, à la maison ou en déplacement",
        category: "facilite"
    },
    {_id: "1116",
        img: canne3,
        images: [canne, canne2, canne3, canne4, canne5,],
        promo: 15,
        productName: "Canne de Marche pliable",
        price: "59.00",
        color: "noir",
        badge: true,
        des :"Canne de marche pliable trusty avec lumière LED\n" +
            " une meilleure stabilité sur la plupart des surfaces\n" +
            " équipée de 3 lumières LED intégrées\n" +
            " (Piles non-incluses)\n" +
            " La canne s’ajuste à votre hauteur facilement\n" +
            " poignée spécialement\n" +
            " une prise confortable et sécuritaire",
        category: "facilite"

    },
    {_id: "1117",
        img: safetyBelt2,
        images: [ safetyBelt, safetyBelt2, safetyBelt3, safetyBelt4, safetyBelt5, safetyBelt6],
        promo: 50,
        productName: "Ceinture Pour Chaise bébé",
        price: "29.00",
        color: "jaune,noir,rouge",
        badge: true,
        des: `Safety Belt™ vous accompagne où que vous soyez !<br />
Sécurisez et libérez en un clip.<br />
Safety Belt™ offre une sécurité haut de gamme tout en garantissant une liberté de mouvement pour votre enfant. Anti-renversement, elle permet aux enfants de manger de manière indépendante.<br /><br />

L’installation est rapide grâce à ses bretelles réglables et douces, ainsi qu’à ses boucles qui tiennent parfaitement. Elle s’adapte à toutes les chaises avec dossier, ainsi qu’à vos genoux. C'est l’alternative parfaite à la chaise haute, que vous pouvez emporter partout avec vous.<br /><br />

<b>Nourrissez n'importe où, sans stress</b><br />
Emportez Safety Belt™ partout pour assurer un repas sécurisé à votre enfant. Que ce soit à la maison ou au restaurant, votre bébé peut désormais participer à tous les repas en famille. Vous pouvez aussi asseoir votre bébé devant vous pour libérer vos mains et réaliser d'autres tâches.<br /><br />

<b>Léger, compact et facile à transporter</b><br />
Safety Belt™ se plie facilement et peut être rangée dans un sac à main, parfait pour les sorties au restaurant ou les voyages. Avec un poids d’environ 110 g et une taille de 48 x 50 cm, elle est adaptée aux enfants de 4 mois à 3 ans.<br /><br />

<b>Matériaux de haute qualité et faciles à entretenir</b><br />
Le tissu en nylon de haute qualité est durable et imperméable, rendant le nettoyage simple avec un chiffon humide ou en machine à laver. Passez plus de temps de qualité avec votre bébé et moins de temps à nettoyer.`,
        category: "facilite"



    },

    {_id: "1118",
        img: robot21,
        images: [robot21, robot22, robot23, robot24,],
        promo: 50,
        productName: " Robot Aspirateur Nettoyeur",
        price: "39.00",
        color: "mauve,noir,gris",
        badge: true,
        des :"Robot Aspirateur Nettoyeur – Nettoyage Efficace et Automatique pour \n" +
            "des Sols Impeccables \n" +
            "Simplifiez votre quotidien avec ce Robot Aspirateur Nettoyeur, capable \n" +
            "de balayer et essuyer le sol simultanément. Grâce à ses fonctions avancées, il \n" +
            "élimine la saleté, les débris et la poussière, sans que vous ayez à lever le petit \n" +
            "doigt. Parfait pour ceux qui cherchent à optimiser leur temps et à profiter d’une \n" +
            "maison toujours propre.\n" +
            " ✄ Caractéristiques Principales :\n" +
            " Fonction Balayage et Lavage : Ce robot combine balayage et vadrouille \n" +
            "pour un nettoyage en profondeur de toutes vos surfaces. Fini les étapes \n" +
            "fastidieuses du nettoyage manuel ! \n" +

            " Nettoyage Automatique : Ce robot détecte la saleté et la poussière et les \n" +
            "élimine efficacement, laissant vos sols propres et éclatants. \n" +
            "Navigation Intelligente : Équipé de capteurs avancés et d’algorithmes de \n" +
            "pointe, il évite les obstacles et les meubles tout en couvrant l'intégralité de \n" +
            "votre maison avec une planification autonome des itinéraires. \n" +
            "Élimination des Débris : Grâce à ses brosses rotatives et son unité \n" +
            "d'aspiration puissante, il capture efficacement la poussière, les débris et \n" +
            "même les poils, pour un environnement domestique plus propre et \n" +
            "confortable. \n" +
            "Facilitez vous la vie avec ce robot aspirateur et nettoyeur, un véritable allié \n" +
            "pour un intérieur toujours propre, sans effort ",
        category: "facilite"

    },
    {_id: "1119",
        img: ceinamein5,
        images: [ ceinamein, ceinamein2, ceinamein3, ceinamein4, ceinamein5,],
        promo: 50,
        productName: " Ceinture Amincissante Vibrante",
        price: "79.00",
        color: "noir",
        badge: true,
        des :" Transformez votre routine de fitness avec la Ceinture Amincissante Vibrante \n" +
            "X5, un dispositif innovant conçu pour faciliter la perte de poids et tonifier votre \n" +
            "corps de manière efficace.\n" +
            " Caractéristiques Principales :\n" +
            " Vibrations Douces : Stimule les muscles abdominaux tout en améliorant la \n" +
            "circulation sanguine dans la zone ciblée, aidant à brûler les graisses et à \n" +
            "augmenter la chaleur corporelle pendant l'activité physique ou l'utilisation \n" +
            "quotidienne.\n" +
            " Personnalisation Complète : Équipée de vitesses et intensités \n" +
            "ajustables, vous pouvez adapter les vibrations selon vos préférences et \n" +
            "vos besoins pour une expérience sur-mesure.\n" +
            " Confort et Ajustement : Dotée d'un système de fermeture réglable, cette \n" +
            "ceinture s'adapte à différentes tailles de tour de taille, garantissant un \n" +
            "confort optimal tout en permettant une liberté de mouvement.\n" +
            " Bénéfices Clés :\n" +
            " Perte de Poids Optimisée : Utilisez-la pendant vos séances de sport pour \n" +
            "maximiser la combustion des graisses et tonifier efficacement la région \n" +
            "abdominale.\n" +
            " Amélioration de la Circulation : Favorise la circulation sanguine et aide à \n" +
            "stimuler le métabolisme, facilitant ainsi une perte de poids plus rapide.\n" +
            " Facile à Utiliser : Une solution simple et pratique pour tous ceux qui \n" +
            "cherchent à améliorer leur silhouette.\n" +
            " Idéale pour ceux qui veulent raffermir leur ventre et \n" +
            "affiner leur taille, la Ceinture Amincissante Vibrante \n" +
            "X5 est votre alliée pour atteindre vos objectifs de \n" +
            "fitness !",
        category: "facilite"

    },
    {_id: "1120",
        img: Astronaut,
        images: [Astronaut, Astronaut2, Astronaut3, Astronaut4, Astronaut5, Astronaut6, Astronaut7,],
        promo: 50,
        productName: "Astronaut Night Light Projector",
        price: "29.00",
        color: "white",
        badge: true,
        des :"Astronauts Starry Sunset Lamp LED Rainbow Neon Night Light Projector\n" +
            " Caractéristiques :\n" +
            " [Rotation à 360 degrés] : La tête de la lampe peut pivoter à 360 degrés et \n" +
            "émettre des lumières sous différents angles, créant une ambiance romantique \n" +
            "et moderne pour votre chambre.\n" +
            " [Robuste et durable] : Fabriquée en plastique et fer de haute qualité. Les \n" +
            "lampes LED économes en énergie et sans éblouissement offrent une longue \n" +
            "durée de vie. La lampe à projection de coucher de soleil fonctionne avec une \n" +
            "alimentation USB de 5V et 5W. Livrée avec un câble USB de 1 mètre.\n" +
            " [Mode d'emploi] : La forme et la taille de l’effet halo du coucher de soleil \n" +
            "peuvent être ajustées en modifiant l’angle et la distance de la tête de la lampe. \n" +
            "Plus la distance est grande, plus la projection est large ; la lumière du coucher \n" +
            "de soleil peut ainsi être projetée sur le mur ou le plafond. La température de \n" +
            "couleur du faisceau est vive, et le projecteur est plus intense ! Insérez le câble \n" +
            "d’alimentation dans le port de charge, branchez le port USB, chargez \n" +
            "complètement, puis allumez l'interrupteur pour l'utiliser.\n" +
            " [Expérience visuelle romantique] : La douce lumière rouge du coucher de \n" +
            "soleil est idéale pour prendre des photos, des vidéos en direct ou des Vlogs. La \n" +
            "lumière du coucher de soleil est facile à utiliser et crée une atmosphère \n" +
            "romantique. Elle peut servir de veilleuse et de lumière d’ambiance, convenant \n" +
            "aux chambres d’enfants, au salon, aux fêtes, aux mariages ou comme arrière\n" +
            "plan de scène. C’est un excellent cadeau pour la famille et les amis",
        category: "decoration"

    },
    {_id: "1121",
        img: sticker,
        images: [sticker, sticker2, sticker3, sticker4, sticker5, sticker6,],
        promo: 50,
        productName: "sticker anti-peluches",
        price: "10.00",
        color: "jaune",
        badge: true,
        des :" Matériau : Notre sticker anti-peluches pour le linge est fabriqué à partir de \n" +
            "matériaux de haute qualité, réutilisables, durables et résistants. Placé dans la \n" +
            "machine à laver avec vos vêtements, il élimine efficacement les poils et les \n" +
            "peluches.\n" +
            " Protection efficace : Conçu en forme ronde, ce récupérateur de poils \n" +
            "empêche les enchevêtrements et les dommages aux vêtements, ce qui le rend \n" +
            "adapté même pour les vêtements d'enfants et assure qu’il ne les abîme pas \n" +
            "pendant le lavage.\n" +
            " Facile à utiliser : Plus besoin de retirer les peluches manuellement. Avec nos \n" +
            "attrape-peluches pour le linge, il suffit de les placer dans la machine à laver et \n" +
            "de lancer le mode essorage.\n" +
            " Pratique : Nos attrape-peluches pour le linge éliminent non seulement les \n" +
            "peluches, poils d’animaux ou autres poils sur les vêtements, vestes et \n" +
            "couvertures, mais ils aident aussi à réduire les dépôts de calcaire, de rouille et \n" +
            "de tartre dans la machine, contribuent à diminuer la saleté et la poussière, et \n" +
            "éliminent les mauvaises odeurs.\n" +
            " Couleurs : 1 pièce de couleur aléatoire (Orange ou Vert)\n" +
            " Matériau : silicone",
        category: "facilite"

    },
    {_id: "1122",
        img: lampeMurale5,
        images: [ lampeMurale, lampeMurale2, lampeMurale3, lampeMurale4, lampeMurale5, lampeMurale6,],
        promo: 30,
        productName: "Lampe murale solaire",
        price: "29.00",
        color: "bois",
        badge: true,
        des :
            " Technologie de capteur photoélectrique de première classe :\n" +
            " Cette lampe murale à capteur extérieur est équipée d’une batterie \n" +
            "rechargeable de 1200mAh.\n" +
            " Mode 1 (Lumière faible + Détection de mouvement) : éclaire \n" +
            "faiblement en permanence, puis intensifie la lumière en cas de \n" +
            "présence, et revient à une lumière faible en l'absence de mouvement.\n" +
            " Mode 2 (Détection de mouvement uniquement) : s’active à pleine \n" +
            "intensité en cas de présence et s’éteint en l’absence de mouvement. \n" +
            "Ce mode permet une autonomie prolongée selon la fréquence de \n" +
            "détection.\n" +
            " Mode 3 (Éclairage constant) : fournit un éclairage continu pendant \n" +
            "environ 2 à 3 heures, puis s'allume automatiquement en présence ou \n" +
            "absence de mouvement.\n" +
            " Cette lampe murale solaire offre une solution d'éclairage extérieur performante \n" +
            "et éco-énergétique, adaptée aux conditions extérieures grâce à son indice de \n" +
            "protection IP65.\n" +
            " Ampoules : 2 ampoules LED\n" +
            " Sortie produit : 5.5V 2W\n" +
            " Capacité de la batterie : 1200mAh\n" +
            " Poids du produit : 300g\n" +
            " Indice d’étanchéité : IP65\n" +
            " Luminosité : 600 lumens (LM)\n" +
            " Matériau : silicium polycristallin + plastique ABS + composants \n" +
            "électroniques\n" +
            " Distance de détection : Capteur infrarouge, 5 à 8 mètres\n" +
            " Dimensions du produit : 14,4 x 4,7 x 14,9 cm\n" +
            " Temps d’éclairage continu : 4 à 5 heures\n" +
            " Durée de vie : 10 000 heures",
        category: "decoration"


    },
    {_id: "1123",
        img: support3,
        images: [ support2, support3],
        promo: 50,
        productName: "Support de Rangement à Ventouse",
        price: "36.00",
        color: "blanc",
        badge: true,
        des :"Transformez votre espace avec le Sucker Storage Rack, une solution pratique \n" +
            "et élégante pour organiser vos objets du quotidien sans perçage ni outils. \n" +
            "Parfait pour les salles de bains, cuisines, garages et plus encore, ce support \n" +
            "polyvalent s’adapte à toutes vos surfaces lisses comme le verre, le carrelage ou \n" +
            "le métal.\n" +
            " Caractéristiques Principales :\n" +
            " Fixation par Ventouse \n" +
            ": Grâce à son système de ventouses puissantes, \n" +
            "ce support s'accroche solidement sans nécessiter de perçage, de clous ou \n" +
            "de vis. Adhère facilement sur des surfaces lisses pour un rangement \n" +
            "pratique et sans dommages.\n" +
            " Matériau Résistant \n" +
            ": Fabriqué en plastique robuste, acier inoxydable \n" +
            "ou aluminium, ce support résiste à la rouille et à la corrosion, parfait pour \n" +
            "les environnements humides comme les salles de bains.\n" +
            " Installation Ultra Facile \n" +
            ": Aucune compétence nécessaire ! Appuyez \n" +
            "simplement sur les ventouses pour les fixer en un instant.\n" +
            " Portabilité et Flexibilité \n" +
            ": Déplacez-le facilement sans laisser de traces \n" +
            "ni de trous. Idéal pour les locataires ou pour réorganiser l'espace selon vos \n" +
            "besoins.\n" +
            " Grande Capacité de Charge \n" +
            ": Peut supporter plusieurs kilogrammes, \n" +
            "parfait pour ranger produits de salle de bain, épices de cuisine, ou même \n" +
            "outils légers.\n" +
            " Utilisation Polyvalente \n" +
            ": Que ce soit pour organiser vos accessoires de \n" +
            "salle de bain, vos ustensiles de cuisine, ou même des objets dans votre \n" +
            "garage, ce support s’adapte à vos besoins.\n" +
            " Design Compact et Élégant \n" +
            ": Conçu pour maximiser l'espace sans \n" +
            "encombrer. Discret et moderne, il s'intègre parfaitement à tout style de \n" +
            "décoration.\n" +
            " Résistance à l'Humidité \n" +
            ": Conçu pour résister aux environnements \n" +
            "humides, ce support est idéal pour une utilisation dans des salles de bains \n" +
            "ou cuisines, gardant vos objets au sec et en sécurité.\n" +
            " Réutilisable et Durable \n" +
            ": Les ventouses peuvent être réutilisées \n" +
            "plusieurs fois, il suffit de les nettoyer ou de les réhumidifier pour une \n" +
            "adhérence renouvelée.\n" +
            " Optez pour le Sucker Storage Rack et profitez d'un rangement pratique et \n" +
            "sans souci pour tous vos espaces",
        category: "maison"

    },
    {_id: "1124",
        img: boudinPorte,
        images: [boudinPorte, boudinPorte2, boudinPorte3, boudinPorte4, boudinPorte5],
        promo: 30,
        productName: "Boudin de Porte",
        price: "39.00",
        color: "blanc,marron",
        badge: true,
        des : "Boudin de Porte Anti Froid anti cafards et anti souris\n" +
            " ✿ 【Version améliorée】 Par rapport aux styles ordinaires du marché, notre \n" +
            "bas de porte double isolant est fait de coton mousse EPE et de tissu non tissé, \n" +
            "ce qui le rend très doux, élastique, imperméable, résistant à l'usure et \n" +
            "indéformable.\n" +
            " ✿ 【Taille universelle】 Boudin bas de porte de longueur : 96 cm. Conception \n" +
            "isolante contre le bruit, les insectes et le vent. Chaque pièce peut être \n" +
            "découpée le long des marques de coupe pour s’adapter parfaitement à vos \n" +
            "besoins spécifiques.\n" +
            " ✿ 【Économie d'énergie】 Les bandes d'étanchéité de porte réduisent les \n" +
            "bruits extérieurs, isolent du froid, de la poussière et du vent, et maintiennent la \n" +
            "température de la pièce, ce qui diminue les factures d'électricité.\n" +
            " ✿ 【Facile à installer】 Il suffit de l’insérer sous la porte, sans colle \n" +
            "nécessaire. Il enveloppe bien le bas de la porte, protégeant ainsi la porte et le \n" +
            "sol des rayures. Convient aux sols carrelés, en bois ou recouverts de tapis.\n" +
            " ✿ 【Nombreuses utilisations】 Idéal pour les portes d’entrée, portes arrière, \n" +
            "portes de garage, portes de chambre et portes de salle de bain. Convient \n" +
            "également aux fenêtres pour bloquer les courants d'air et maintenir la \n" +
            "température.\n" +
            " Fabriqué en Italie",
        category: "maison"

    },
    {_id: "1125",
        img: MopNettoyage,
        images: [ MopNettoyage5, MopNettoyage4, MopNettoyage3, MopNettoyage2, MopNettoyage, ],
        promo: 20,
        productName: "Mop à vaporisation d'eau ",
        price: "30.00",
        color: "bleu,rouge",
        badge: true,
        des : "Tampon en microfibre remplaçable et réutilisable : Le tampon en microfibre peut être facilement changé et réutilisé, ce qui est économique et écologique.\n" +
            "\n" +
            "• Buse plate : La buse plate répartit le produit de nettoyage de manière uniforme, permettant un nettoyage efficace du sol.\n" +
            "\n" +
            "• Balai à vaporisation pour un nettoyage rapide : Ce balai est conçu pour nettoyer les sols rapidement et sans effort.\n" +
            "\n" +
            "• Forme rectangulaire : Sa forme rectangulaire permet de le glisser facilement autour des meubles et des obstacles, nettoyant chaque recoin de la pièce.\n" +
            "\n" +
            "Caractéristiques :\n" +
            "\n" +
            "Catégorie de produit : Balai plat\n" +
            "Type de balai : Balai plat\n" +
            "Longueur déployée : 121 cm\n" +
            "Taille de la pince : 33,12 cm\n" +
            "Matériau du tampon : Microfibre\n" +
            "Type de fixation du tampon : Bouton adhésif\n" +
            "Matériau de la tige : Acier inoxydable\n" +
            "Capacité de charge de la tige : Moins de 10 kg\n" +
            "Couleur : Blanc\n" +
            "Poids : 600 g",
        category: "facilite"

    },
    {_id: "1126",
        img: MassageTete,
        images: [ MassageTete,MassageTete2,MassageTete3,MassageTete4,],
        promo: 25,
        productName: "Masseur Automatique pour la Tête",
        price: "55.00",
        color: "blanc,gris",
        badge: true,
        des : "Découvrez le Masseur de Cuir Chevelu Électrique Rechargeable, parfait pour \n" +
            "une relaxation apaisante et un soulagement du stress. Conçu avec une \n" +
            "technologie de pointe, il procure un massage du cuir chevelu unique et stimule \n" +
            "la santé des cheveux.\n" +
            " Avec quatre modes de vibration personnalisables, cet appareil offre un \n" +
            "massage adapté à vos besoins, des pulsations douces aux vibrations intenses. \n" +
            "Sa fonction d'arrêt automatique assure une utilisation sécurisée et \n" +
            "économique, tandis que son design ergonomique et léger le rend idéal à la \n" +
            "maison ou en déplacement.\n" +
            " En plus de favoriser la détente, il stimule les follicules capillaires pour des \n" +
            "cheveux plus sains. Offrez-vous une expérience spa quotidienne et profitez de \n" +
            "ses bienfaits revitalisants",
        category: "facilite"

    },

    {_id: "1127",
        img: salleDeBain2,
        images: [salleDeBain,salleDeBain2,salleDeBain3, ],
        promo: 30,
        productName: "Ensemble d'Accessoires de Salle de Bain",
        price: "69.00",
        color: "Gris anthracite",
        badge: true,
        des : "Composants :\n" +
            "Porte-brosse à dents : Design cylindrique avec des divisions internes.\n" +
            "Distributeur de savon : Design élancé avec une pompe en acier.\n" +
            "Porte-savon : Forme ronde avec des rainures pour drainer l'eau.\n" +
            "Gobelet : Cylindrique, idéal pour rincer ou pour y mettre des brosses à dents.\n",
        category: "maison"

    },



    {_id: "1129",
        img:sechLinge ,
        images: [sechLinge,sechLinge2,sechLinge3, ],
        promo: 20,
        productName: "Séchoir à linge costo pliable",
        price: "59.00",
        color: "gris",
        badge: true,
        des : "\n" +
            "Le sèche-linge de la marque tunisienne Sotufab est conçu pour offrir une solution pratique de séchage pour la maison. Ce modèle présente une large capacité, permettant de sécher une grande quantité de linge en une seule fois. Grâce à sa conception pliable, il prend peu de place une fois rangé, et il est facile à transporter et à déplacer, même chargé de vêtements, grâce à ses roulettes intégrées.\n" +
            "\n" +
            "Caractéristiques principales :\n" +
            "Dimensions (ouvert) : L 183 x P 55 x H 112 cm\n" +
            "Structure multi-niveaux : 3 étendoirs, dont un plus large pour les vêtements volumineux\n" +
            "Filet de séchage : Multi-usage, situé sous les étendoirs\n" +
            "Coffret de pinces à linge : Pratique pour maintenir les vêtements en place\n" +
            "Roues de déplacement : 2 roulettes pour faciliter la mobilité du sèche-linge même avec des vêtements mouillés\n" +
            "Ce sèche-linge allie praticité et gain d’espace, idéal pour un usage quotidien dans les maisons nécessitant un séchage rapide et efficace du linges",
        category: "maison"

    },





     {_id: "1133",
      img: file2,
      images: [file1,file2,file3,file4, ],
      promo: 20,
      productName: "épilateur a fil",
      price: "21.00",
      color: "blanc",
      badge: true,
      des : "enlever les poils du visage à la racine sans endommager la peau comme la cire ou le blanchiment.",
      category: "facilite"

     },

   {_id: "1134",
    img: retro4,
    images: [retro4,retro2,retro3,retro1,retro5,retro6, ],
    promo: 30,
    productName: "film anti-buée pour retroviseur de voiture x2",
    price: "22.00",
    color: "mirroité",
    badge: true,
    des : "Notre protecteur de rétroviseur est conçu avec un film protecteur en PET, une couche anti-reflets et un nano-revêtement, offrant une haute transparence et réduisant la fatigue visuelle.\n" +
        "\n" +
        "Caractéristiques principales :\n" +
        "Polyvalent : Imperméable, antibuée, anti-éblouissement, anti-rayures, et anti-taches pour une visibilité optimale par temps de pluie ou de brouillard.\n" +
        "Résistant : Protège efficacement vos rétroviseurs des rayures, salissures et poussières, tout en conservant sa forme.\n" +
        "Installation rapide et facile :\n" +
        "Nettoyez la surface du miroir pour enlever poussières et impuretés.\n" +
        "Essuyez avec un chiffon en microfibre doux.\n" +
        "Vaporisez de l'eau sur le miroir.\n" +
        "Appliquez le film protecteur.\n" +
        "Éliminez les bulles d'air avec une raclette.\n" +
        "Retirez la seconde couche du film.",
       category: "facilite"


   },






    {
        _id: "1138",
        img: LampeCrystal5,
        images: [  LampeCrystal5,LampeCrystal2,LampeCrystal3,LampeCrystal4,LampeCrystal,],
        promo: 30,
        productName: "Lampe Crystal LED Tactile",
        price: "35.00",
        color: "Crystal",
        badge: true,
        des: " \"Lampe Diamant : L'élégance lumineuse avec contrôle tactile pour styler votre intérieur !\" ✨\n" +
            "💎 Design élégant en forme de diamant, idéal pour toutes vos pièces.\n" +
            "💡 3 couleurs de lumière au choix : blanche, jaune, et chaude, contrôlées par un simple toucher.\n" +
            "🔋 Batterie ultra-puissante : jusqu’à 10 heures d’autonomie, rechargeable en USB.\n" +
            "✨ Lumière éclatante et chic, parfaite pour votre maison !\n" +
            "\n" +
            "👉 Parfaite pour la chambre, le salon ou toute autre ambiance cosy. Commandez dès maintenant ! ",
        category:"decoration"
    },

      {_id: "1139",
       img: LampeDiamant2,
       images: [ LampeDiamant,LampeDiamant2,LampeDiamant3,LampeDiamant4,LampeDiamant5, ],
       promo: 30,
       productName: "Lampe Diamant LED a Commande",
       price: "35.00",
       color: "Crystal",
       badge: true,
       des : "Caractéristiques :\n" +
           "\n" +
           "Design Brillant : Lampe de chevet avec un design unique en forme de diamant, combinant simplicité et luxe moderne. Lumière élégante et douce, non éblouissante.\n" +
           "Sources de Lumière Multiples : 3 températures de couleur réglables via un interrupteur tactile, offrant une lumière délicate et naturelle, idéale pour la santé de votre famille.\n" +
           "Dimming Tactile Progressif : Sensibilité de lumière ajustable (lumière jaune, blanche, chaude) pour s'adapter à vos besoins.\n" +
           "Indicateur de Charge : Batterie rechargeable de 2000mAh offrant jusqu'à 10+ heures d'autonomie, avec charge USB sécurisée et arrêt automatique une fois pleine.\n" +
           "Corps Transparent : Fabriqué en acrylique de haute qualité avec un design prismatique multifacette, diffusant une lumière éclatante comme un diamant.\n" +
           "Spécifications :\n" +
           "\n" +
           "Matériau : Acrylique et PC\n" +
           "Commande : Capteur tactile\n" +
           "Charge : Batterie rechargeable 2000mAh, 8-10 heures d'éclairage continu\n" +
           "Couleurs de lumière : 3 (blanc, jaune, chaud) ou 16 couleurs RGB (contrôle par télécommande)\n" +
           "Puissance : 3W\n" +
           "Voltage : 5V\n" +
           "Applications : Salon, table de chevet, chambre à coucher\n" +
           "Inclus dans le paquet :\n" +
           "\n" +
           "1 lampe de bureau\n" +
           "1 câble de charge USB",
          category:"decoration"

      },

      {_id: "1140",
       img: Rouleau,
       images: [Rouleau,Rouleau2,Rouleau3,Rouleau4,Rouleau5, ],
       promo: 20,
       productName: "3 rouleaux de peinture a réservoir",
       price: "35.00",
       color: "bois",
       badge: true,
       des : "Transformez vos travaux de peinture avec ce pack innovant de 3 rouleaux de peinture à réservoir intégré. Conçus pour simplifier votre expérience, ces rouleaux vous permettent de peindre rapidement et efficacement, sans besoin de bac à peinture.\n" +
           "\n" +
           "Caractéristiques principales :\n" +
           "\n" +
           "Réservoir intégré : Chaque rouleau dispose d’un réservoir intégré pour remplir directement de peinture. Peignez plus longtemps sans interruptions.\n" +
           "Application uniforme : Le rouleau est conçu pour distribuer la peinture de manière homogène, assurant une finition lisse et professionnelle.\n" +
           "Facile à utiliser : Remplissez simplement le réservoir, fermez, et commencez à peindre. Plus besoin de manipuler des bacs encombrants.\n" +
           "Convient à tous les types de surfaces : Idéal pour les murs, plafonds, portes et plus encore.\n" +
           "Facile à nettoyer : Une fois votre travail terminé, nettoyez simplement le rouleau pour une réutilisation immédiate.",
          category: "facilite"
    },

      {_id: "1141",
       img: Vantouse2,
       images: [Vantouse2,Vantouse,Vantouse3,Vantouse4, ],
       promo: 30,
       productName: "Vantouse multifonctionnelle",
       price: "23.00",
       color: "noir",
       badge: true,
       des : "1-Matériau: Plastique + alliage d'aluminium + caoutchouc\n" +
           "2-Dimension: 118mm de diamètre\n" +
           "3-Idéal pour soulever le verre, tirer les bosses, ajuster le sol stratifié, manipuler les lunettes, le matériau en tôle, les fenêtres, le granit, le miroir, le plancher en planches et toute surface propre et non poreuse; réparer les espaces de plancher stratifié. Peut être utilisé comme extracteur de bosses pour la voiture, le réfrigérateur ou le corps de la machine à laver.\n" +
           "4-Lorsque vous appliquez la ventouse sur un objet, la surface de l'objet doit être plane, propre, lisse et non poreuse.",
       category: "facilite"
    },

      {_id: "1142",
       img: Vitre,
       images: [Vitre,Vitre2,Vitre3,Vitre4,],
       promo: 20,
       productName: "Nettoyeur de Vitres 3-en-1",
       price: "20.00",
       color: "vert",
       badge: true,
       des : "Cet outil de nettoyage de vitres est un véritable multitâche pour votre routine de nettoyage. Il est équipé d'un vaporisateur intégré, d'une raclette, et d'une serviette pour vous permettre de nettoyer efficacement les surfaces vitrées.\n" +
           "\n" +
           "Utilisez-le pour éliminer la saleté, le calcaire, les taches d'eau, et bien plus encore. Il est également idéal pour les portes de douche. Grâce à sa conception résistante à l'humidité, il peut être utilisé longtemps, même dans des conditions humides.\n" +
           "\n" +
           "Simplifiez votre routine de nettoyage avec cet outil pratique qui combine pulvérisation, raclage, et essuyage en un seul geste.",
       category: "facilite"
    },



    /*
     {_id: "1143",
      img: lampeMurale5,
      images: [carBleutooth,carBleutooth2,carBleutooth3,carBleutooth4, ],
      promo: 30,
      productName: "Lampe murale solaire",
      price: "29.00",
      color: "bois",
      badge: true,
      des : "",
   },
    */
    /*
     {_id: "1143",
      img: lampeMurale5,
      images: [carBleutooth,carBleutooth2,carBleutooth3,carBleutooth4, ],
      promo: 30,
      productName: "Lampe murale solaire",
      price: "29.00",
      color: "bois",
      badge: true,
      des : "",
   },
    */
    /*
     {_id: "1143",
      img: lampeMurale5,
      images: [carBleutooth,carBleutooth2,carBleutooth3,carBleutooth4, ],
      promo: 30,
      productName: "Lampe murale solaire",
      price: "29.00",
      color: "bois",
      badge: true,
      des : "",
   },
    */








];
// =================== PaginationItems End here =================
export const bestSeller = [
    {_id: "1134",
        img: retro4,
        images: [retro4,retro2,retro3,retro1,retro5,retro6, ],
        promo: 30,
        productName: "film anti-buée pour retroviseur de voiture x2",
        price: "22.00",
        color: "mirroité",
        badge: true,
        des : "Notre protecteur de rétroviseur est conçu avec un film protecteur en PET, une couche anti-reflets et un nano-revêtement, offrant une haute transparence et réduisant la fatigue visuelle.\n" +
            "\n" +
            "Caractéristiques principales :\n" +
            "Polyvalent : Imperméable, antibuée, anti-éblouissement, anti-rayures, et anti-taches pour une visibilité optimale par temps de pluie ou de brouillard.\n" +
            "Résistant : Protège efficacement vos rétroviseurs des rayures, salissures et poussières, tout en conservant sa forme.\n" +
            "Installation rapide et facile :\n" +
            "Nettoyez la surface du miroir pour enlever poussières et impuretés.\n" +
            "Essuyez avec un chiffon en microfibre doux.\n" +
            "Vaporisez de l'eau sur le miroir.\n" +
            "Appliquez le film protecteur.\n" +
            "Éliminez les bulles d'air avec une raclette.\n" +
            "Retirez la seconde couche du film.",

    },
    {_id: "1124",
        img: boudinPorte,
        images: [boudinPorte, boudinPorte2, boudinPorte3, boudinPorte4, boudinPorte5],
        promo: 30,
        productName: "Boudin de Porte",
        price: "39.00",
        color: "blanc,marron",
        badge: true,
        des : "Boudin de Porte Anti Froid anti cafards et anti souris\n" +
            " ✿ 【Version améliorée】 Par rapport aux styles ordinaires du marché, notre \n" +
            "bas de porte double isolant est fait de coton mousse EPE et de tissu non tissé, \n" +
            "ce qui le rend très doux, élastique, imperméable, résistant à l'usure et \n" +
            "indéformable.\n" +
            " ✿ 【Taille universelle】 Boudin bas de porte de longueur : 96 cm. Conception \n" +
            "isolante contre le bruit, les insectes et le vent. Chaque pièce peut être \n" +
            "découpée le long des marques de coupe pour s’adapter parfaitement à vos \n" +
            "besoins spécifiques.\n" +
            " ✿ 【Économie d'énergie】 Les bandes d'étanchéité de porte réduisent les \n" +
            "bruits extérieurs, isolent du froid, de la poussière et du vent, et maintiennent la \n" +
            "température de la pièce, ce qui diminue les factures d'électricité.\n" +
            " ✿ 【Facile à installer】 Il suffit de l’insérer sous la porte, sans colle \n" +
            "nécessaire. Il enveloppe bien le bas de la porte, protégeant ainsi la porte et le \n" +
            "sol des rayures. Convient aux sols carrelés, en bois ou recouverts de tapis.\n" +
            " ✿ 【Nombreuses utilisations】 Idéal pour les portes d’entrée, portes arrière, \n" +
            "portes de garage, portes de chambre et portes de salle de bain. Convient \n" +
            "également aux fenêtres pour bloquer les courants d'air et maintenir la \n" +
            "température.\n" +
            " Fabriqué en Italie",
        category: "maison"

    },
    {_id: "1121",
        img: sticker,
        images: [sticker, sticker2, sticker3, sticker4, sticker5, sticker6,],
        promo: 50,
        productName: "sticker anti-peluches",
        price: "10.00",
        color: "jaune",
        badge: true,
        des :" Matériau : Notre sticker anti-peluches pour le linge est fabriqué à partir de \n" +
            "matériaux de haute qualité, réutilisables, durables et résistants. Placé dans la \n" +
            "machine à laver avec vos vêtements, il élimine efficacement les poils et les \n" +
            "peluches.\n" +
            " Protection efficace : Conçu en forme ronde, ce récupérateur de poils \n" +
            "empêche les enchevêtrements et les dommages aux vêtements, ce qui le rend \n" +
            "adapté même pour les vêtements d'enfants et assure qu’il ne les abîme pas \n" +
            "pendant le lavage.\n" +
            " Facile à utiliser : Plus besoin de retirer les peluches manuellement. Avec nos \n" +
            "attrape-peluches pour le linge, il suffit de les placer dans la machine à laver et \n" +
            "de lancer le mode essorage.\n" +
            " Pratique : Nos attrape-peluches pour le linge éliminent non seulement les \n" +
            "peluches, poils d’animaux ou autres poils sur les vêtements, vestes et \n" +
            "couvertures, mais ils aident aussi à réduire les dépôts de calcaire, de rouille et \n" +
            "de tartre dans la machine, contribuent à diminuer la saleté et la poussière, et \n" +
            "éliminent les mauvaises odeurs.\n" +
            " Couleurs : 1 pièce de couleur aléatoire (Orange ou Vert)\n" +
            " Matériau : silicone",
        category: "facilite"

    },
    {_id: "1140",
        img: Rouleau,
        images: [Rouleau,Rouleau2,Rouleau3,Rouleau4,Rouleau5, ],
        promo: 20,
        productName: "3 rouleaux de peinture a réservoir",
        price: "35.00",
        color: "bois",
        badge: true,
        des : "Transformez vos travaux de peinture avec ce pack innovant de 3 rouleaux de peinture à réservoir intégré. Conçus pour simplifier votre expérience, ces rouleaux vous permettent de peindre rapidement et efficacement, sans besoin de bac à peinture.\n" +
            "\n" +
            "Caractéristiques principales :\n" +
            "\n" +
            "Réservoir intégré : Chaque rouleau dispose d’un réservoir intégré pour remplir directement de peinture. Peignez plus longtemps sans interruptions.\n" +
            "Application uniforme : Le rouleau est conçu pour distribuer la peinture de manière homogène, assurant une finition lisse et professionnelle.\n" +
            "Facile à utiliser : Remplissez simplement le réservoir, fermez, et commencez à peindre. Plus besoin de manipuler des bacs encombrants.\n" +
            "Convient à tous les types de surfaces : Idéal pour les murs, plafonds, portes et plus encore.\n" +
            "Facile à nettoyer : Une fois votre travail terminé, nettoyez simplement le rouleau pour une réutilisation immédiate.",
        category: "facilite"
    },

    {_id: "1141",
        img: Vantouse2,
        images: [Vantouse2,Vantouse,Vantouse3,Vantouse4, ],
        promo: 30,
        productName: "Vantouse multifonctionnelle",
        price: "23.00",
        color: "noir",
        badge: true,
        des : "1-Matériau: Plastique + alliage d'aluminium + caoutchouc\n" +
            "2-Dimension: 118mm de diamètre\n" +
            "3-Idéal pour soulever le verre, tirer les bosses, ajuster le sol stratifié, manipuler les lunettes, le matériau en tôle, les fenêtres, le granit, le miroir, le plancher en planches et toute surface propre et non poreuse; réparer les espaces de plancher stratifié. Peut être utilisé comme extracteur de bosses pour la voiture, le réfrigérateur ou le corps de la machine à laver.\n" +
            "4-Lorsque vous appliquez la ventouse sur un objet, la surface de l'objet doit être plane, propre, lisse et non poreuse.",
        category: "facilite"
    },

    {_id: "1142",
        img: Vitre,
        images: [Vitre,Vitre2,Vitre3,Vitre4,],
        promo: 20,
        productName: "Nettoyeur de Vitres 3-en-1",
        price: "20.00",
        color: "vert",
        badge: true,
        des : "Cet outil de nettoyage de vitres est un véritable multitâche pour votre routine de nettoyage. Il est équipé d'un vaporisateur intégré, d'une raclette, et d'une serviette pour vous permettre de nettoyer efficacement les surfaces vitrées.\n" +
            "\n" +
            "Utilisez-le pour éliminer la saleté, le calcaire, les taches d'eau, et bien plus encore. Il est également idéal pour les portes de douche. Grâce à sa conception résistante à l'humidité, il peut être utilisé longtemps, même dans des conditions humides.\n" +
            "\n" +
            "Simplifiez votre routine de nettoyage avec cet outil pratique qui combine pulvérisation, raclage, et essuyage en un seul geste.",
        category: "facilite"
    },
    {_id: "1113",
        img: brosseVitres,
        images: [brosseVitres, brosseVitres2, brosseVitres3, brosseVitres4, brosseVitres5],
        promo: 50,
        productName: "Nettoyeur  de vitres magnétique",
        price: "29.00",
        color: "jaune",
        badge: true,
        des :": avec une conception magnétique double face \n" +
            "puissante, ce nettoyeur de vitres magnétique double face vous permet de \n" +
            "nettoyer votre fenêtre sans avoir à risquer de vous tenir debout à la fenêtre. \n" +
            "L'éponge de stockage d'eau intégrée peut être utilisée pendant une longue \n" +
            "période, évitant le réapprovisionnement répété en eau et facile à utiliser. Corde \n" +
            "anti-chute plus sûre : l' outil de nettoyage de vitres magnétique est livré avec 1 \n" +
            "longue corde anti-chute sûre, lorsque vous utilisez cet outil de nettoyage de \n" +
            "vitres, la corde sera attachée à votre main, ne tombant jamais. Vous permet \n" +
            "d'essuyer le verre de manière plus sûre, vous pouvez nettoyer l'extérieur en \n" +
            "vous tenant debout dans la pièce ; Très important : le verre de différentes \n" +
            "épaisseurs doit être utilisé sur différents essuie-glaces magnétiques, sinon il ne \n" +
            "peut pas bouger ou tomber du verre. Vous pouvez apprendre la méthode de \n" +
            "mesure de l'épaisseur du verre. Appliquez du détergent : assurez-vous \n" +
            "d'appliquer du détergent sur le coton de nettoyage, sinon le grattoir est difficile \n" +
            "à déplacer. Déplacez lentement le nettoyeur de vitres magnétique pendant \n" +
            "l'utilisation, plus la vitesse de déplacement est lente, meilleur est l'effet de \n" +
            "nettoyage. Prenez soin de votre main : l'article est très magnétique, veuillez \n" +
            "prendre soin de vos mains pendant le processus de demande. Lorsque vous \n" +
            "ouvrez l'essuie-glace, tenez la poignée à deux mains et faites pivoter l'essuie\n" +
            "glace pour l'écarter. Lors de sa fermeture, à l'exception de 3 à 8 mm, les autres \n" +
            "essuie-glaces ont des cloisons. Utilisez des cloisons pour séparer les essuie\n" +
            "glaces, veillez à ne pas vous pincer les doigts ici.\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " ●\n" +
            " Matériau: plastique\n" +
            " Utilisez la scène: maison\n" +
            " Taille: 16*13.5cm\n" +
            " Épaisseur: 3 -8mm\n" +
            " Forme: triangle\n" +
            " Le paquet comprend:\n" +
            " 1 * Nettoyeur de vitres à double couches\n",
        category: "facilite",

    },


    {
        _id: "1103",
        img: raclette2,
        images: [raclette2, raclette1,  raclette3 , raclette4],
        productName: "Mini Mop Rotatif à Essorage",
        price: "29.00",
        color: "vert , blanc",
        badge: true,
        promo:20,
        des: "Découvrez le meilleur de la simplicité pour le nettoyage domestique avec notre \n" +
            "Mini Mop Rotatif à Essorage Sans Lavage à la Main pour Sols. Ce balai innovant \n" +
            "est conçu pour simplifier votre routine de nettoyage et offre de superbes \n" +
            "performances sur plusieurs types de surfaces.\n" +
            " Caractéristiques principales :\n" +
            " Nettoyage sans les mains : Dites adieu au lavage manuel des tampons de balai. \n" +
            "Notre design sans lavage à la main permet un nettoyage facile et hygiénique, \n" +
            "sans vous salir les mains.\n" +
            " Nettoyage multi-surfaces : Ce mini balai rotatif à essorage est une solution \n" +
            "tout-en-un pour nettoyer de nombreuses surfaces. Nettoyez les sols et bien \n" +
            "plus encore avec un outil polyvalent.\n" +
            " Action d'essorage efficace : La fonction rotative garantit un nettoyage complet \n" +
            "et efficace. Les taches et la saleté tenaces ne résistent pas aux puissants \n" +
            "résultats de ce balai.\n" +
            " Mécanisme d'essorage : Obtenez le niveau d'humidité idéal pour vos tâches de \n" +
            "nettoyage grâce au mécanisme d'essorage pratique. Plus d’excès d'eau, juste \n" +
            "des sols impeccables.\n" +
            " Compact et portable : Conçu pour un rangement et une portabilité faciles, ce \n" +
            "mini balai est parfait pour les petits espaces. Gardez vos outils de nettoyage à \n" +
            "portée de main sans encombrer votre maison.\n" +
            " Améliorez votre routine de nettoyage avec le Mini Mop Rotatif à Essorage Sans \n" +
            "Lavage à la Main pour Sols. Profitez d’un nettoyage sans les mains, multi\n" +
            "surfaces, d'une action d’essorage efficace et d'un design compact. Facilitez \n" +
            "vos tâches de nettoyage !",
        category: "F_OPTICS",
    },

    {_id: "1125",
        img: MopNettoyage,
        images: [ MopNettoyage5, MopNettoyage4, MopNettoyage3, MopNettoyage2, MopNettoyage, ],
        promo: 20,
        productName: "Mop à vaporisation d'eau ",
        price: "30.00",
        color: "bleu,rouge",
        badge: true,
        des : "Tampon en microfibre remplaçable et réutilisable : Le tampon en microfibre peut être facilement changé et réutilisé, ce qui est économique et écologique.\n" +
            "\n" +
            "• Buse plate : La buse plate répartit le produit de nettoyage de manière uniforme, permettant un nettoyage efficace du sol.\n" +
            "\n" +
            "• Balai à vaporisation pour un nettoyage rapide : Ce balai est conçu pour nettoyer les sols rapidement et sans effort.\n" +
            "\n" +
            "• Forme rectangulaire : Sa forme rectangulaire permet de le glisser facilement autour des meubles et des obstacles, nettoyant chaque recoin de la pièce.\n" +
            "\n" +
            "Caractéristiques :\n" +
            "\n" +
            "Catégorie de produit : Balai plat\n" +
            "Type de balai : Balai plat\n" +
            "Longueur déployée : 121 cm\n" +
            "Taille de la pince : 33,12 cm\n" +
            "Matériau du tampon : Microfibre\n" +
            "Type de fixation du tampon : Bouton adhésif\n" +
            "Matériau de la tige : Acier inoxydable\n" +
            "Capacité de charge de la tige : Moins de 10 kg\n" +
            "Couleur : Blanc\n" +
            "Poids : 600 g",
        category: "facilite"

    },
    {
        _id: "1105",
        img: purificateur4,
        images: [ purificateur4, purificateur, purificateur2 , purificateur5, purificateur6, purificateur7],
        productName: "Purificateur d'eau de Robinet",
        promo: 40,
        price: "59.00",
        color: "Blanc",
        badge: true,
        des : "Purificateur de Robinet : Protégez Votre Santé avec une Eau Pure et Sûre 🚰\n" +
            "L'eau est essentielle à notre quotidien, mais les impuretés présentes dans l'eau du robinet peuvent avoir un impact négatif sur notre santé. Pour assurer une eau propre et saine, optez pour notre Purificateur de Robinet. Cet appareil pratique et efficace vous garantit une eau filtrée, prête à être utilisée pour boire, cuisiner, ou même pour vos soins de la peau.\n" +
            "\n" +
            "Caractéristiques Principales :\n" +
            "Petit et Pratique : Compact et facile à installer, ce purificateur ne nécessite aucune électricité, ce qui le rend idéal pour une utilisation domestique.\n" +
            "Filtration à 7 Couches : Offre une filtration précise et efficace, éliminant les sédiments, la rouille, les vers rouges et autres impuretés de l'eau du robinet.\n" +
            "Élément Filtrant en Céramique : Assure une filtration de haute précision (0,1 micron) pour une eau purifiée en profondeur.\n" +
            "Longue Durée de Vie : L'élément filtrant peut être nettoyé pour prolonger sa durée de vie jusqu'à 3 à 6 mois, en fonction de la qualité de l'eau.\n" +
            "Installation Facile : Fourni avec des adaptateurs et connecteurs pour s'adapter à différents types de robinets, il s'installe en un rien de temps.\n" +
            "Spécifications Techniques :\n" +
            "Matériau de l'élément filtrant : Céramique\n" +
            "Couleur : Blanc\n" +
            "Température de l'eau appropriée : 0 ~ 38°C\n" +
            "Débit : 1 L/Min\n" +
            "Durée de vie de l'élément filtrant : 3 à 6 mois\n" +
            "Taille du purificateur : 15011565 mm\n" +
            "Poids du colis : 400 g\n" +
            "Contenu du Paquet :\n" +
            "1 * Purificateur d'eau (avec élément filtrant)\n" +
            "4 * Convertisseurs de robinet\n" +
            "1 * Connecteur multifonctionnel\n" +
            "2 * Rondelles\n" +
            "1 * Manuel d'utilisation\n" +
            "💧 Investissez dans la santé de votre famille avec notre purificateur de robinet. Profitez d'une eau pure, propre et exempte d'impuretés à tout moment.",
    },

    {
        _id: "1107",
        img: turboDouche,
        images: [turboDouche, turboDouche2, turboDouche3, turboDouche4, turboDouche5],
        promo: 25,
        productName: "Douchette Turbo avec 3 Modes",
        price: "39.00",
        color: "chrome",
        badge: true,
        des : "1. Fabriqué en ABS de haute qualité, sûr et respectueux de l'environnement, résistant à l'usure et aux chutes.\n" +
            "\n" +
            "2. La sélection multi-modes répond aux besoins de chacun pour la douche. Avec la fonction de tête de massage, vous pouvez masser la tête pour vous détendre pendant la douche.\n" +
            "\n" +
            "3. Utilisez différents modes pour rincer la tête, et la douche avec filtre permet de garder l'eau propre.\n" +
            "\n" +
            "4. La surface lisse ne raye pas les mains ; qu'il s'agisse d'un adulte ou d'un enfant, il peut être utilisé en toute confiance.\n" +
            "\n" +
            "Longueur du tuyau de douche : 1,5 m/59,06 pouces\n" +
            "Fonctions : 3 modes\n" +
            "Poids : 250 g\n" +
            "Dimensions du produit : 25 x 7 cm/9,84 x 2,75 pouces\n" +
            "\n" +
            "Matériau de la tête de douche : plastique ABS + filtre en coton PP\n" +
            "Matériau du support de tête de douche : plastique ABS\n" +
            "Matériau du tuyau de douche : acier inoxydable, Écrou : acier inoxydable, Noyau : laiton",
    },

    {
        _id: "1108",
        img: manteau3,
        images: [manteau3 , manteau1, manteau2, manteau4, manteau5, manteau6,manteau7],
        promo: 20,
        productName: "Manteau Imperméable",
        price: "29.00",
        color: "jaune,vert",
        badge: true,
        des : "Manteau Imperméable, Protection Pluie\n" +
            " Ce poncho écologique et innovant offre un équilibre parfait entre performance \n" +
            "et durabilité. Fabriqué avec un matériau imperméable, respirant et résistant à \n" +
            "l'abrasion, il garantit un confort maximal, même sous des pluies torrentielles.\n" +
            " Principaux atouts :\n" +
            " Protection complète : Sa grande taille couvre également le sac à dos, \n" +
            "protégeant de la pluie et du vent.\n" +
            " Confort supérieur : Le matériau évacue l'humidité, offrant une sensation \n" +
            "de fraîcheur et de bien-être.\n" +
            " Polyvalence : Idéal pour diverses activités extérieures, comme la \n" +
            "randonnée, le camping, les festivals et les déplacements quotidiens.\n" +
            " Facilité d'utilisation : Il se met facilement sur les vêtements, assurant une \n" +
            "protection rapide.\n" +
            " Ce poncho allie design moderne et technologie de pointe, idéal pour ceux qui \n" +
            "recherchent une protection fiable et respectueuse de l'environnement.",
    },

    {
        _id: "1101",
        img: robot2,
        images: [robot2, robot1,  robot3 , robot4],
        productName: "Robot Aspirateur de Nettoyage Intelligent",
        price: "59.00",
        color: "noir , blanc",
        badge: true,
        promo:30,
        des: "Robot Aspirateur de Nettoyage Intelligent – La Solution Moderne pour \n" +
            "une Maison Propre \n" +
            "Le Robot Aspirateur de Nettoyage Intelligent est conçu pour vous offrir une \n" +
            "maison impeccablement propre avec un minimum d'effort. Doté \n" +
            "d’une technologie avancée et d’un design pratique, cet appareil rechargeable \n" +
            "par USB assure un nettoyage en profondeur, quel que soit le type de sol, tout \n" +
            "en étant silencieux et facile à utiliser. AVANTAGES :\n" +
            " Nettoyage Automatique : Élimine poussière, poils et peluches de manière \n" +
            "autonome, sans aucune intervention manuelle. \n" +
            "Ultra Silencieux : Fonctionne avec une technologie de réduction du \n" +
            "bruit pour ne pas perturber votre quotidien. \n" +
            "Recharge USB Pratique : Se recharge facilement via n'importe quel \n" +
            "port USB, vous offrant flexibilité et commodité. \n" +
            "Gain de Temps : Libérez du temps pour vos activités préférées, pendant \n" +
            "que le robot se charge du nettoyage. \n" +
            "CARACTÉRISTIQUES :\n" +
            " Mode de Frottement Manuel : Simule le frottement des mains pour un \n" +
            "nettoyage plus minutieux. \n" +
            "Nettoyage en Profondeur : Couvre une large surface et capture même les \n" +
            "plus petites particules. \n" +
            "Design Universel : Le robot change de direction automatiquement en \n" +
            "rencontrant des obstacles, garantissant une couverture complète de votre \n" +
            "espace. \n" +
            "Sécurité Anti-Chute : Équipé d’un système anti-chute pour éviter les \n" +
            "accidents. \n" +
            "SPÉCIFICATIONS :\n" +
            " Nom du Produit : Robot Aspirateur de Nettoyage Intelligent\n" +
            " Tension de Fonctionnement : 7 V / 1500 mAh\n" +
            " Temps de Charge : Environ 3 heures (chargement USB)\n" +
            " Niveau Sonore : Environ 55 dB\n" +
            " Autonomie : Jusqu’à 90 minutes d’utilisation continue \n" +
            "Batterie : Batterie intégrée\n" +
            " Poids : Léger et facile à déplacer \n" +
            "Ne laissez plus le ménage envahir votre emploi du temps. Commandez dès \n" +
            "aujourd'hui le Robot Aspirateur de Nettoyage Intelligent et profitez d'un \n" +
            "intérieur propre sans effort !",

        category: "F_OPTICS",

    },
    {_id: "1118",
        img: robot21,
        images: [robot21, robot22, robot23, robot24,],
        promo: 50,
        productName: " Robot Aspirateur Nettoyeur",
        price: "39.00",
        color: "mauve,noir,gris",
        badge: true,
        des :"Robot Aspirateur Nettoyeur – Nettoyage Efficace et Automatique pour \n" +
            "des Sols Impeccables \n" +
            "Simplifiez votre quotidien avec ce Robot Aspirateur Nettoyeur, capable \n" +
            "de balayer et essuyer le sol simultanément. Grâce à ses fonctions avancées, il \n" +
            "élimine la saleté, les débris et la poussière, sans que vous ayez à lever le petit \n" +
            "doigt. Parfait pour ceux qui cherchent à optimiser leur temps et à profiter d’une \n" +
            "maison toujours propre.\n" +
            " ✄ Caractéristiques Principales :\n" +
            " Fonction Balayage et Lavage : Ce robot combine balayage et vadrouille \n" +
            "pour un nettoyage en profondeur de toutes vos surfaces. Fini les étapes \n" +
            "fastidieuses du nettoyage manuel ! \n" +

            " Nettoyage Automatique : Ce robot détecte la saleté et la poussière et les \n" +
            "élimine efficacement, laissant vos sols propres et éclatants. \n" +
            "Navigation Intelligente : Équipé de capteurs avancés et d’algorithmes de \n" +
            "pointe, il évite les obstacles et les meubles tout en couvrant l'intégralité de \n" +
            "votre maison avec une planification autonome des itinéraires. \n" +
            "Élimination des Débris : Grâce à ses brosses rotatives et son unité \n" +
            "d'aspiration puissante, il capture efficacement la poussière, les débris et \n" +
            "même les poils, pour un environnement domestique plus propre et \n" +
            "confortable. \n" +
            "Facilitez vous la vie avec ce robot aspirateur et nettoyeur, un véritable allié \n" +
            "pour un intérieur toujours propre, sans effort ",
        category: "facilite"

    },

];

